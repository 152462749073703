export const SET_FILTERED_ITEMS = 'innovA/search#setFilteredItems';
export const REMOVE_FILTERED_ITEM = 'innovA/search#removeFilteredItem';
export const CLEAR_FILTERS = 'innovA/search#clearFilters';
export const GET_SUGESTED_FILTER_ITEMS = 'innovA/search#getSuggestedFilterItems';
export const GET_INVENTORY = 'innovA/search#getInventory';
export const LOADING = 'innovA/search#loading';
export const SET_YEARS = 'innovA/search#setYears';
export const SET_MAKES = 'innovA/search#setMakes';
export const SET_MODELS = 'innovA/search#setModels';
export const SET_PAGINATION = 'innovA/search#setPagination';
export const SET_CATEGORIES = 'innovA/search#setCatergories';
export const SET_LOCATIONS = 'innovA/search#setLocations';
export const SET_BOOLEAN = 'innovA/search#setBoolean';
export const REMOVE_BOOLEAN = 'innovA/search#removeBoolean';
export const GET_SEARCH_CRITERIA = 'innovA/search#getSearchCriteria';
export const SET_FAMILIES = 'innovA/search#setFamilies';
export const GET_MAKES_FOR_MODELS = 'innovA/search#getMakesForModels';
export const CLEAR_MODELS_FILTER_CHECK = 'innovA/search#clearModelsFilter';
export const SET_SORT_VALUE = 'innovA/search#setSortValue';
export const SET_FILTERED_DATES = 'innovA/search#setFilteredDates';
export const SET_FILTERED_IDS = 'innovA/search#setFilteredIDs';
export const SET_STATUSFILTER = 'innovA/search#setStatusFilter';
export const RESET_LOADER = 'innovA/search#resetLoader';
export const CLEAR_SEARCH = 'innovA/search#clearSearch';
export const SET_ITEM_NAVIGATOR = 'innovA/search#setItemNavigator';