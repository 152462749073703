import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';
// import { getCartTotal } from '../../services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
    cartItems: [],
    transactionFeeTotal: 0,
}

const addToCart = (cartItems, item) => {
    for (let i = 0; i < cartItems.length; i++) {
        if (item.item_ID === cartItems[i].item_ID) {
            return true;
        }
    }
    toast.success("Item Added to Cart");
}

export default createReducer(initialState, {
    [actionTypes.ADD_TO_CART]: (state, action) => ({ ...state, cartItems: addToCart(state.cartItems, action.item) ? state.cartItems : state.cartItems.concat(action.item) }),
    [actionTypes.REMOVE_FROM_CART]: (state, action) => {
        toast.success("Item Removed from Cart");
        return { 
            ...state, 
            cartItems: state.cartItems.filter(item => item.item_ID !== action.item.item_ID),
         }  
    },
    [actionTypes.INCREMENT_QTY]: (state, action) => {
        return {...state, ...action.item.qty += 1}
    },
    [actionTypes.DECREMENT_QTY]: (state, action) => {
        return {...state, ...action.item.qty -= 1}
    },
    [actionTypes.CALCULATE_TOTAL]: (state, action) => {
        return { ...state, 
            ...action.item.onInvoice = !action.item.onInvoice,
         }
    },
    [actionTypes.UPDATE_CART_STATE]: (state, action) => ({...state, transactionFeeTotal: action.payload.object}),
});