import { createAuthRemoteCall } from "../../store/utils";
import * as actionTypes from "./action-types";
import { logout } from "../authorization";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { push } from "connected-react-router";

export const getContactInfo = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_CONTACT_INFO },
    (api) => api.account.getContactInfo,
    logout
  )(params);

  export const updateContactBidderTag = (params) => async (dispatch) => {
    const result = await dispatch(
      createAuthRemoteCall(
        { fetch: actionTypes.UPDATE_CONTACT_BIDDERTAG},
        (api) => api.account.updateContactBidderTag,
        logout
      )(params)
    );
  
    return result;
  };

export const getUserInformation = () => async (dispatch) => {
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_USER_INFO, loading: actionTypes.LOADING_USER },
      (api) => api.account.getUserInformation,
      logout
    )()
  );

  return result;
};

export const getHandOffToken = () => async (dispatch) => {
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_HANDOFF_TOKEN },
      (api) => api.account.getHandOffToken,
      logout
    )()
  );

  if (!result.error) {
    return result.payload.token;
  }

  return result;
};

export const getUserOfferHistory = (params) =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_USER_OFFER_HISTORY,
      loading: actionTypes.LOADING_USER_OFFERS,
    },
    (api) => api.account.getUserOfferHistory,
    logout
  )(params);

export const getUserWonPurchaseHistory = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_WONPURCHASED_ITEMS },
    (api) => api.account.getUserWonPurchaseHistory,
    logout
  )(params);

export const getUserLostItemHistory = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_LOST_ITEMS },
    (api) => api.account.getUserLostItemHistory,
    logout
  )(params);

export const getRegisteredEvents = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_REGISTERED_AUCTIONS },
    (api) => api.account.getRegisteredAuctions,
    logout
  )(params);

export const updateAccountState = (item) => ({
  type: actionTypes.UPDATE_ACCOUNT_STATE,
  payload: { object: item },
});

export const setExternalUserPermissions = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.SET_USER_PERMISSIONS },
    (api) => api.account.setExternalUserPermissions,
    logout
  )(params);

export const getExternalUserPermissions = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_USER_PERMISSIONS },
    (api) => api.account.getExternalUserPermissions,
    logout
  )(params);

export const getListedItems = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_LISTED_ITEMS, loading: actionTypes.LOADING_ITEMS },
    (api) => api.account.getListedItems
  )(params);

export const getSoldItems = () =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_SOLD_ITEMS, loading: actionTypes.LOADING_ITEMS },
    (api) => api.account.getSoldItems
  )();

export const createUserPaymentProfile = () =>
  createAuthRemoteCall(
    { fetch: actionTypes.CREATE_USER_PAYMENT_PROFILE },
    (api) => api.payment.createCustomerPaymentProfile,
    logout
  )();

export const getUserPaymentMethods = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_USER_PAYMENT_METHODS },
    (api) => api.payment.getUserPaymentMethods,
    logout
  )(params);

export const editUserPaymentMethod = (data) => async (dispatch, getState) => {
  let month = data.expiry.substring(0, 2);
  let year = data.expiry.substring(5, 7);

  const {
    userAccount: { paymentType },
  } = getState();

  const params = {
    paymentProfileID: data.paymentMethodData && data.paymentMethodData.id,
    firstName: data.firstName,
    lastName: data.lastName,
    billingAddress: data.addressLine1.value,
    billingCity: data.physicalCity,
    billingPostal: data.physicalPostalCode,
    billingState: data.physicalState.label,
    paymentType: paymentType,
    isDefault: data.isDefaultPayment,
    creditCardNumber: data.cardNumber,
    creditCardCode: data.cvc,
    creditCardExpireDateMonth: month,
    creditCardExpireDateYear: year,
    accountType: paymentType,
    routingNumber: data.routingNumber,
    accountNumber: data.accountNumber,
    nameOnAccount: data.nameOnAccount,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.EDIT_USER_PAYMENT_METHOD,
        loading: actionTypes.LOADING_ADD_USER_PAYMENT,
      },
      (api) => api.payment.editUserPaymentMethod,
      logout
    )(params)
  );
  if (!result.error) {
    toast.success(`Payment Method Updated Successfully`);
    await dispatch(getSavedPaymentMethods());
    return true;
  } else if (
    result &&
    result.payload &&
    !result.payload.creditCardResult.success
  ) {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  } else {
    toast.error(`${result.messages[0]}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    dispatch(
      updateAccountState({
        loading_add_user_payment: true,
      })
    );
  }
};

export const deleteUserPaymentMethod = (data) => async (dispatch) => {
  const params = {
    paymentProfileID: data.id,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.DELETE_USER_PAYMENT_METHOD,
        loading: actionTypes.LOADING_ADD_USER_PAYMENT,
      },
      (api) => api.payment.deleteUserPaymentMethod,
      logout
    )(params)
  );
  if (!result.error) {
    toast.success(`Payment Method Removed Successfully`);
    await dispatch(getSavedPaymentMethods());
  } else if (
    result &&
    result.payload &&
    !result.payload.creditCardResult.success
  ) {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  } else {
    toast.error(`${result.messages[0]}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    dispatch(
      updateAccountState({
        loading_add_user_payment: true,
      })
    );
  }
};

export const chargeUserPaymentProfile = (params) => async (
  dispatch,
  getState
) => {
  const { userAccount } = getState();
  dispatch(
    updateAccountState({ ...userAccount, hasError: false, errorText: "" })
  );
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.CHARGE_USER_PAYMENT_PROFILE },
      (api) => api.payment.chargeCustomerPaymentProfile,
      logout
    )(params)
  );
  if (result.error !== undefined) {
    await dispatch(
      updateAccountState({ hasError: true, errorText: result.messages[0] })
    );
    return false;
  }
  if (result.payload.transactionResponse !== null) {
    if (result.payload.transactionResponse.errors !== null) {
      await dispatch(
        updateAccountState({
          hasError: true,
          errorText: result.payload.transactionResponse.errors[0].errorText,
        })
      );
      return false;
    } else if (result.payload.transactionResponse.responseCode === "1") {
      if (result.payload.transactionResponse.transId !== null) {
        return result.payload.transactionResponse.transId;
      } else {
        await dispatch(
          updateAccountState({
            hasError: true,
            errorText: result.payload.messages.message[0].text,
          })
        );
        return false;
      }
    }
  } else {
    await dispatch(
      updateAccountState({
        hasError: true,
        errorText: result.payload.messages.message[0].text,
      })
    );
    return false;
  }
};

export const getInvoices = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_INVOICES, loading: actionTypes.LOADING_INVOICES },
    (api) => api.account.getInvoices,
    logout
  )(params);

export const getInvoiceHTML = (params) =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_INVOICE_HTML,
      loaded: actionTypes.LOADING_GET_INVOICE_HTML,
    },
    (api) => api.account.getInvoiceHTML,
    logout
  )(params);

export const getReleaseTicketHTML = (params) =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_RELEASE_TICKET_HTML,
      loading: actionTypes.LOADING_GET_RELEASE_TICKET_HTML,
    },
    (api) => api.account.getReleaseTicketHTML,
    logout
  )(params);

export const setReleaseTicketPDFDownload = (item) => ({
  type: actionTypes.LOADING_RELEASE_DOWNLOAD_PDF,
  payload: item,
});

export const setInvoicePDFDownload = (item) => ({
  type: actionTypes.LOADING_DOWNLOAD_PDF,
  payload: item,
});

export const getInvoicePDF = (params) => async (dispatch) => {
  dispatch(setInvoicePDFDownload({ id: params, spinner: true }));

  const data = {
    invoice_ID: params,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_INVOICE_PDF },
      (api) => api.account.getInvoicePDF,
      logout,
      true
    )(data)
  );

  if (!result.error) {
    await saveAs(result.payload, `Invoice #${params}`);
    await dispatch(setInvoicePDFDownload({ id: params, spinner: false }));
  }
};

export const getReleaseTicketPDF = (params) => async (dispatch) => {
  dispatch(setReleaseTicketPDFDownload({ id: params, spinner: true }));

  const data = {
    item_ID: params,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_RELEASE_TICKET_PDF },
      (api) => api.account.getReleaseTicketPDF,
      logout,
      true
    )(data)
  );

  if (!result.error) {
    await saveAs(result.payload, `Item #${params}`);
    await dispatch(setReleaseTicketPDFDownload({ id: params, spinner: false }));
  }
};

export const payInvoice = (data, callback) => async (dispatch, getState) => {
  let amount;
  if (typeof data.amount === "number") {
    amount = data.amount;
  } else {
    amount = data.amount.replace(/\$/g, "").replace(/,/g, "");
  }

  const {
    userAccount: {
      surcharge: { fee },
    },
  } = getState();

  const params = {
    ccv: data.ccv,
    invoice_ID: data.invoice_ID,
    amount: parseFloat(amount),
    paymentMethod_ID: data.paymentMethod_ID.paymentMethod_ID,
    profile_ID: String(data.paymentMethod_ID.id),
    convenienceFee: fee,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.PAY_INVOICE,
        loading: actionTypes.LOADING_PAY_INVOICE,
      },
      (api) => api.payment.payInvoice,
      logout
    )(params)
  );

  if (!result.error && callback) {
    callback();
    toast.success("Payment Applied");
    return result;
  } else {
    dispatch(
      updateAccountState({
        paymentError: result.messages[0],
        loading_payment: false,
      })
    );
  }
};

export const getSavedPaymentMethods = () =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_PAYMENT_METHODS,
      loading: actionTypes.LOADING_PAYMENT_METHODS,
    },
    (api) => api.payment.getSavedPaymentMethods,
    logout
  )();

export const addCustomerPaymentMethod = (data) => async (dispatch) => {
  let month = data.expiry.substring(0, 2);
  let year = data.expiry.substring(5, 7);

  const params = {
    firstName: data.firstName,
    lastName: data.lastName,
    billingAddress:
      data.billingLine1 && (data.billingLine1.value || data.billingLine1),
    billingCity: data.billingCity,
    billingPostal: data.billingPostalCode,
    billingState:
      data.billingState && (data.billingState.label || data.billingState),
    billingCountry_ID: data.billing_country_ID.id,
    paymentType: data.paymentType && data.paymentType.value,
    isDefault: data.isDefault,
    creditCardNumber: data.cardNumber,
    creditCardCode: data.cvc,
    creditCardExpireDateMonth: month,
    creditCardExpireDateYear: year,
    accountType: data.accountType && data.accountType.value,
    routingNumber: data.routingNumber,
    accountNumber: data.accountNumber,
    nameOnAccount: data.nameOnCard,
    registrationVerificationCard: data.registrationVerificationCard || false,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.ADD_CUSTOMER_PAYMENT_METHOD,
        loading: actionTypes.LOADING_CUSTOMER_ADD_PAYMENT,
      },
      (api) => api.payment.addCustomerPaymentMethod,
      logout
    )(params)
  );

  if (
    !result.error &&
    result.payload.creditCardResult &&
    result.payload.creditCardResult.success &&
    !data.isFromMyAccount
  ) {
    dispatch(updateAccountState({ hasVerifiedPaymentMethod: true, step: 1 }));
    dispatch(push("/success"));
  } else if (
    (data.isFromMyAccount &&
      result.payload.creditCardResult &&
      result.payload.creditCardResult.success &&
      !result.error) ||
    (result &&
      result.payload.bankAccountResult &&
      result.payload.bankAccountResult.success)
  ) {
    toast.success("Payment Method Added");
    dispatch(updateAccountState({ hasVerifiedPaymentMethod: true }));
    dispatch(getSavedPaymentMethods());
    return result;
  } else if (
    result &&
    result.payload.creditCardResult &&
    !result.payload.creditCardResult.success
  ) {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  } else if (
    result &&
    result.payload.bankAccountResult &&
    !result.payload.bankAccountResult.success
  ) {
    toast.error(`${result.payload.bankAccountResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  } else {
    toast.error(`${result.messages[0]}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    dispatch(
      updateAccountState({
        loading_add_user_payment: true,
      })
    );
  }
  return result;
};

export const payInvoicecc = (data, callback) => async (dispatch, getState) => {
  let amount;

  if (typeof data.amount === "number") {
    amount = data.amount;
  } else {
    amount = data.amount.replace(/\$/g, "").replace(/,/g, "");
  }

  let month = data.expiry.substring(0, 2);
  let year = data.expiry.substring(5, 7);

	const {
    userAccount: {
      surcharge: { fee },
    },
  } = getState();

  const params = {
    invoice_ID: data.invoice_ID,
    amount: parseFloat(amount),
    cardNumber: data.cardNumber,
    expireMonth: parseFloat(month),
    expireYear: parseFloat(year),
    ccv: data.cvc,
    name: data.nameOnCard,
    address: data.billingLine1.value || data.billingLine1,
    city: data.billingCity,
    state: data.billingState.value || data.billingState,
    postalCode: data.billingPostalCode.toString(),
    savePaymentMethod: data.savePaymentMethod,
		convenienceFee: fee
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.PAY_INVOICE_CC,
        loading: actionTypes.LOADING_PAY_INVOICE,
      },
      (api) => api.payment.payInvoicecc,
      logout
    )(params)
  );

  const {
    userAccount: {
      accountInfo: { account_ID },
    },
  } = getState();

  if (result.payload && result.payload.creditCardResult.success && callback) {
    toast.success("Payment Accepted");
    callback();
    await dispatch(getInvoices({ account_ID: account_ID }));
    return result;
  } else {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  }
};

export const creditCardDeposit = (data) => async (dispatch) => {
  let amount;
  if (typeof data.amount === "number") {
    amount = data.amount;
  } else {
    amount = data.amount.replace(/\$/g, "").replace(/,/g, "");
  }

  let month = data.expiry.substring(0, 2);
  let year = data.expiry.substring(5, 7);

  const params = {
    amount: parseFloat(amount),
    cardNumber: data.cardNumber,
    expireMonth: parseFloat(month),
    expireYear: parseFloat(year),
    ccv: data.cvc,
    name: data.nameOnCard,
    address: data.billingLine1.label || data.billingLine1,
    city: data.billingCity,
    state: data.billingState.label || data.billingState,
    postalCode: data.billingPostalCode,
    savePaymentMethod: data.savePaymentMethod,
  };
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.CREDIT_CARD_DEPOSIT,
        loading: actionTypes.LOADING_DEPOSIT_METHOD,
      },
      (api) => api.payment.creditCardDeposit,
      logout
    )(params)
  );

  if (result.payload && result.payload.creditCardResult.success) {
    toast.success("Deposit Accepted");
    await dispatch(getUserInformation());
  } else {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  }

  return result;
};

export const savedMethodDeposit = (data) => async (dispatch) => {
  let amount;
  if (typeof data.amount === "number") {
    amount = data.amount;
  } else {
    amount = data.amount.replace(/\$/g, "").replace(/,/g, "");
  }

  const params = {
    amount: parseFloat(amount),
    paymentProfile_ID: data.paymentMethod_ID.id,
    ccv: data.cvc,
    paymentMethod_ID: data.paymentMethod_ID.paymentMethod_ID,
  };
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.SAVED_METHOD_DEPOSIT,
        loading: actionTypes.LOADING_DEPOSIT_METHOD,
      },
      (api) => api.payment.savedMethodDeposit,
      logout
    )(params)
  );

  if (result.payload && result.payload.creditCardResult.success) {
    toast.success("Deposit Accepted");
    await dispatch(getUserInformation());
  } else {
    toast.error(`${result.payload.creditCardResult.errorMessage}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
  }

  return result;
};

export const getSurcharge = (data) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_SURCHARGE },
    (api) => api.payment.getSurcharge,
    logout
  )(data);

export const clearSurcharge = (item) => ({
  type: actionTypes.CLEAR_SURCHARGE,
  payload: { object: item },
});

export const updatePhysicalAddress = (data, callback) => async (dispatch) => {
  const isInternational = data.physical_country_ID.id !== 0;

  const params = {
    line1: isInternational ? data.addressLine1 : data.addressLine1.value,
    line2: data.addressLine2,
    city: data.physicalCity,
    state: isInternational ? data.physicalState : data.physicalState.value,
    postalCode: data.physicalPostalCode,
    country_ID: data.physical_country_ID.id,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.UPDATE_PHYSICAL_ADDRESS,
        loading: actionTypes.LOADING_UPDATE_ADDRESS,
      },
      (api) => api.account.updatePhysicalAddress,
      logout
    )(params)
  );

  if (!result.error && callback) {
    callback();
    dispatch(getUserInformation());
  }
};

export const updateBillingAddress = (data, callback) => async (dispatch) => {
  const isInternational = data.billing_country_ID.id !== 0;

  const params = {
    line1: isInternational ? data.billingLine1 : data.billingLine1.value,
    line2: data.billingLine2,
    city: data.billingCity,
    state: isInternational ? data.billingState : data.billingState.value,
    postalCode: data.billingPostalCode,
    country_ID: data.billing_country_ID.id,
  };
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.UPDATE_BILLING_ADDRESS,
        loading: actionTypes.LOADING_UPDATE_ADDRESS,
      },
      (api) => api.account.updateBillingAddress,
      logout
    )(params)
  );

  if (!result.error && callback) {
    callback();
    dispatch(getUserInformation());
  }
};

export const updateAccountAddress = (address) => ({
  type: actionTypes.UPDATE_ACCOUNT_ADDRESS,
  payload: address,
});

export const updatePhysicalAddressV2 = (data, callback) => async (dispatch) => {
  const isInternational = data.physical_country_ID.id !== 0;

  const params = {
    account_ID: data.account_ID,
    line1: isInternational ? data.addressLine1 : data.addressLine1.value,
    line2: data.addressLine2,
    city: data.physicalCity,
    state: isInternational ? data.physicalState : data.physicalState.value,
    postalCode: data.physicalPostalCode,
    country_ID: data.physical_country_ID.id,
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.UPDATE_PHYSICAL_ADDRESS_V2,
      },
      (api) => api.account.updatePhysicalAddressV2,
      logout
    )(params)
  );

  if (!result.error && callback) {
    callback();
    dispatch(
      updateAccountAddress({
        ...result.payload,
        account_ID: data.account_ID,
        type: "physicalAddress",
      })
    );
  }
};

export const updateBillingAddressV2 = (data, callback) => async (dispatch) => {
  const isInternational = data.billing_country_ID.id !== 0;

  const params = {
    account_ID: data.account_ID,
    line1: isInternational ? data.billingLine1 : data.billingLine1.value,
    line2: data.billingLine2,
    city: data.billingCity,
    state: isInternational ? data.billingState : data.billingState.value,
    postalCode: data.billingPostalCode,
    country_ID: data.billing_country_ID.id,
  };
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.UPDATE_BILLING_ADDRESS_V2,
      },
      (api) => api.account.updateBillingAddressV2,
      logout
    )(params)
  );

  if (!result.error && callback) {
    callback();
    dispatch(
      updateAccountAddress({
        ...result.payload,
        account_ID: data.account_ID,
        type: "billingAddress",
      })
    );
  }
};

export const getTaxExemptionTypes = (params) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_TAX_EXEMPTION_TYPES },
    (api) => api.account.getTaxExemptionTypes,
    logout
  )(params);

// export const addTaxExemption = (params) =>
//   createAuthRemoteCall(
//     { fetch: actionTypes.ADD_TAX_EXEMPTION },
//     (api) => api.auction.addTaxExemption,
//     logout
//   )(params);

export const addTaxExemption = (params) => async (dispatch) => {
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.ADD_TAX_EXEMPTION,
      },
      (api) => api.auction.addTaxExemption,
      logout
    )(params)
  );

  if (!result.error) {
    dispatch(getContactInfo());
  }
};

export const updateTermsAcceptance = (isAccepted) => ({
  type: actionTypes.UPDATE_TERMS_ACCEPTANCE,
  payload: isAccepted,
});