import { createSelector } from 'reselect';

export const denormalizeEntitiesArray = createSelector(
    entities => entities || {},
    entities => {
      return Object.keys(entities).reduce((result, key) => {
        result.push(entities[key]);
  
        return result;
      }, []);
    },
  );