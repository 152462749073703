import { createAuthRemoteCall } from "../../store/utils";
import * as actionTypes from "./action-types";
import { toast } from "react-toastify";
import { loadingEventRegister, updateItemState } from "../auction";
import { updateAuctionState } from "../live-auction";
import { getUpcomingAuctionsPreview } from "../main";

export const getUpcomingEvents = (data) => async (dispatch, getState) => {
  if (getState().events.loadedEvents) {
    return { payload: { events: getState().events.upcomingEvents } }
  }
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET, loading: actionTypes.LOADING_UPCOMING_EVENTS },
      (api) => api.events.getUpcomingEventsSorted
    )(data));

  return result
}

export const getTodaysEvents = (data) => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.GET_TODAYS_EVENTS }, (api) => api.auction.getTodaysEvents)(data));

  if (!result.error || callback) {
    callback();
  }
};

export const getPastEvents = (data) =>
  createAuthRemoteCall(
    { fetch: actionTypes.GET_PAST, loading: actionTypes.LOADING_PAST_EVENTS },
    (api) => api.events.getPastEvents
  )(data);

export const getAuctionResults = (data) =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_AUCTION_RESULTS,
      loading: actionTypes.LOADING_RESULTS,
    },
    (api) => api.events.getAuctionResults
  )(data);

export const validateTermsAcceptanceByEventId = (data) => async (
  dispatch,
  callback
) => {
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.VALIDATE_TERMS_ACCEPTANCE_BY_EVENT_ID },
      (api) => api.events.validateTermsAcceptanceByEventId
    )(data)
  );
  if (result.error) {
    toast.error(`${result.messages[0]}`, {
      position: "top-right",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    return result
  }
  return result.payload;
};

export const getAllUpcoming = (data) =>
  createAuthRemoteCall(
    {
      fetch: actionTypes.GET_ALL,
      loading: actionTypes.LOADING_UPCOMING_EVENTS,
    },
    (api) => api.events.getUpcomingEvents
  )(data);

export const getRegisteredEvents = (item) => ({
  type: actionTypes.GET_REGISTERED_EVENTS,
  payload: item,
});

export const getUpcomingEventsSorted = (data, forced) => async (dispatch, getState) => {
  // const events = getState().events;
  // console.log(forced)
  // if (!forced && events.loadedEvents && (data.pageNumber === events.sortedEvents.pageNumber)) {
  //  return { payload: events.sortedEvents }
  // }
  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.GET_UPCOMING_EVENTS_SORTED,
        loading: actionTypes.LOADING_UPCOMING_EVENTS,
      },
      (api) => api.events.getUpcomingEventsSorted
    )(data));
  return result;
}

export const registerForAuctions = (data, callback) => async (
  dispatch,
  getState
) => {
  const {
    router: {
      location: { pathname },
    },
    liveAuction: { buyingPower },
  } = getState();

  if (pathname.includes("item")) {
    dispatch(loadingEventRegister());
  }
  const payload = {
    registrations: [
      {
        event_ID: data.event_ID,
        textNotifications: data.txtNotification,
        emailNotifications: data.emailNotification,
        register: !data.isRegistered,
      },
    ],
  };

  const result = await dispatch(
    createAuthRemoteCall(
      {
        fetch: actionTypes.REGISTER_FOR_AUCTIONS,
        loading: actionTypes.LOADING_REGISTERD_FOR_EVENTS,
      },
      (api) => api.events.registerForAuctions
    )(payload)
  );

  if (!result.error) {
    if (pathname.includes("item")) {
      if (callback) callback();
      dispatch(
        updateItemState({
          userRegisteredForItemEvent: true,
          loadingEventRegister: true,
        })
      );
      dispatch(
        updateAuctionState({
          buyingPower: { ...buyingPower, isRegistered: true },
        })
      );
    } else if (
      !pathname.includes("live-auction") &&
      !pathname.includes("simulcast") &&
      !pathname.includes('dynamic-auction')
    ) {
      // dispatch({
      //   type: actionTypes.UPDATE_REGISTERED_EVENTS_STATE,
      //   payload: data,
      // });
      dispatch(getUpcomingAuctionsPreview());
      if (callback) {
        callback();
      }
    } else {
      dispatch(
        updateAuctionState({
          loadingEventLiveRegister: true,
          buyingPower: { ...buyingPower, isRegistered: true },
        })
      );
      if (callback) callback();
    }
    toast.success(
      data.register ? "Registration Successful" : "Unregistered Successfully"
    );
  } else {
    dispatch({ type: actionTypes.REGISTER_FOR_AUCTIONS });
    toast.error(`${result.messages[0]}`, {
      position: "top-right",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    if (callback) callback();
    dispatch(updateItemState({ loadingEventRegister: true }));
  }
};

export const updateEventStateValue = (value) => ({
  type: actionTypes.UPDATE_EVENT_STATE_VALUE,
  payload: { object: value },
});

export const loadingTodaysEvents = () => ({
  type: actionTypes.LOADING_TODAYS_EVENTS,
});

export const setPagination = (item) => ({
  type: actionTypes.SET_PAGINATION,
  payload: { object: item },
});

export const updateRegisteredEventsState = (item) => ({
  type: actionTypes.UPDATE_REGISTERED_STATE,
  payload: item,
});

export const unregisterForEvent = (item) => ({
  type: actionTypes.REMOVE_EVENT_REGISTRATION,
  payload: item,
});
