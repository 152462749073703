import React, { useState, lazy } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { login } from "../../../reducers/authorization";
import {
	createExternalUser,
	requestPasswordResetWelcome,
	requestPasswordReset,
	updateRegisterState,
	getCountries,
	validateEmail,
} from "../../../reducers/register";
import { setIsNavEvent } from "../../../reducers/main";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import { resetAuthError } from "../../../reducers/authorization";

const Login = lazy(() => import("./login"));
const Register = lazy(() => import("./register"));
const EmailVerification = lazy(() => import("./verification"));
const ForgotPassword = lazy(() => import("./forgot-password"));

const LoginRegister = (props) => {
	const {
		isOpen,
		toggle,
		error,
		login,
		createExternalUser,
		passwordRequest,
		regError,
		requestPasswordResetWelcome,
		requestPasswordReset,
		push,
		getCountries,
		countries,
		emailAddress,
		updateRegisterState,
		isNavEvent,
		setIsNavEvent,
		location,
		isResetPassord,
		validate_email_loading,
		validateEmail,
		resetAuthError,
	} = props;

	const [ifSignIn, setIfSignIn] = useState(true);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [registerForm, setRegisterForm] = useState(true);
	

	const handleResendVerify = async () => {
		if (forgotPassword) {
			await requestPasswordReset({
				entity_ID: 1,
				emailAddress: emailAddress,
				baseURL: process.env.REACT_APP_BASE_URL,
				callbackURL: location.pathname,
			});
		} else {
			await requestPasswordResetWelcome({
				entity_ID: 1,
				emailAddress: emailAddress,
				baseURL: process.env.REACT_APP_BASE_URL,
				callbackURL: location.pathname,
			});
		}
	};

	const handleForgotPassword = () => {
		setForgotPassword(!forgotPassword);
	};

	const handleCloseModal = () => {
		sessionStorage.setItem("incentive", "true");
		resetAuthError(); 
		toggle();
	};

	const handleOpen = () => {
		updateRegisterState({ error: "" });
	};

	const handleRegisterFromIncentive = () => {
		setRegisterForm(false);
		setIsNavEvent({ isNavEvent: true });
		setIfSignIn(false);
		sessionStorage.setItem("incentive", "true");
	};

	const Footer = () => {
		return !registerForm ? null : (
			<React.Fragment>
				<Row style={{ width: "100%" }}>
					<Col xs={6}>
						<Button type="button" onClick={() => handleCloseModal()} block>
							Cancel
						</Button>
					</Col>
					<Col xs={6}>
						<Button type="button" onClick={() => handleRegisterFromIncentive()} block>
							Get Started
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		);
	};

	const handleSignUpSignIn = () => {
		if (forgotPassword) {
			setForgotPassword(false);
			setIfSignIn(true);
		} else {
			setIfSignIn(!ifSignIn);
		}
	};

	const handleResetForgotPassword = () => {
		setForgotPassword(!forgotPassword);
		if (passwordRequest) {
			updateRegisterState({ passwordRequest: false });
		}
	};

	const handleModalTitle = () => {
		if (ifSignIn && !registerForm && !forgotPassword) {
			return "sign in to continue";
		} else if (!ifSignIn && !passwordRequest) {
			return "Register";
		} else if (forgotPassword) {
			return (
				<label style={{ cursor: "pointer" }} onClick={() => handleResetForgotPassword()}>
					<i className="fa fa-chevron-left"></i> forgot password
				</label>
			);
		} else if (passwordRequest) {
			return "";
		} else if (registerForm && !isNavEvent) {
			return "Sign in or register";
		} else if (isNavEvent && !forgotPassword) {
			return "Sign in to continue";
		}
	};

	return (
		<Modal
			onOpened={() => handleOpen()}
			isOpen={isOpen}
			toggle={() => handleCloseModal()}
			className="modal-dialog-centered"
			backdrop="static">
			<ModalHeader toggle={() => handleCloseModal()}>{handleModalTitle()}</ModalHeader>
			<ModalBody>
				{passwordRequest ? (
					<EmailVerification
						{...{
							validateEmail,
							emailAddress,
							regError,
							updateRegisterState,
							isResetPassord,
							validate_email_loading,
							toggle,
							forgotPassword,
						}}
						handleResendVerify={handleResendVerify}
					/>
				) : ifSignIn ? (
					forgotPassword ? (
						<ForgotPassword {...{ requestPasswordReset, location }} />
					) : (
						<Login
							{...{
								login,
								toggle,
								handleForgotPassword,
								setIsNavEvent,
							}}
							errorText={error}
						/>
					)
				) : (
					<Register
						{...{
							requestPasswordResetWelcome,
							createExternalUser,
							toggle,
							regError,
							push,
							countries,
							getCountries,
							setIsNavEvent,
							registerForm,
						}}
						initialValues={{
							phoneType: "Mobile",
							country: { label: "United States", id: 0 },
							hiddenField: "",
						}}
					/>
				)}
			</ModalBody>
			{registerForm && !isNavEvent ? (
				<ModalFooter>
					<Footer />
				</ModalFooter>
			) : passwordRequest ? null : (
				<ModalFooter className="justify-content-center">
					<React.Fragment>
						{ifSignIn && !forgotPassword ? "Don't have an account?" : "Already have an account?"}
						<Button onClick={() => handleSignUpSignIn()} color="link">
							{ifSignIn && !forgotPassword ? "Sign up" : "Sign in"}
						</Button>
					</React.Fragment>
				</ModalFooter>
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	error: state.authorization.error,
	regError: state.register.error,
	isResetPassord: state.register.isResetPassord,
	passwordRequest: state.register.passwordRequest,
	emailAddress: state.register && state.register.emailAddress,
	countries: state.register && state.register.countries,
	location: state.router.location,
	validate_email_loading: state.register.validate_email_loading,
});

const mapDispatchToProps = {
	login,
	createExternalUser,
	requestPasswordResetWelcome,
	requestPasswordReset,
	push,
	getCountries,
	updateRegisterState,
	setIsNavEvent,
	validateEmail,
	resetAuthError,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginRegister));
