import React, { useState, lazy } from 'react'
import { Card, Button, Row, Col, Badge } from 'reactstrap'
import moment from 'moment'
import { EventTypes, EventTypeStrings } from '../../../../constants/event-types';
import { EventStatus, EventStatusStrings } from '../../../../constants/event-status'
import { push } from "react-router-redux";
import { connect } from "react-redux";
import LiveIcon from "../../../common/live-icon"

import {
    getTodaysEvents,
} from "../../../../reducers/events";
import EventRegistration from '../registration';


const TodaysEvents = (props) => {
    const { todaysEvents, handleRegisterForEvent, getTodaysEvents } = props;
    return (
        <React.Fragment>
            <Col xs="12">
                <div className="heading-wrapper">
                    <h2 className="gray">Today's Auctions</h2>
                </div>
            </Col>
            {todaysEvents.map(auction => (
                <Col key={auction.event_ID} xs="12" sm="6" lg="4" className="pb-4">
                    <Card className="h-100 shadow-sm p-3">
                        <div className="mb-4 flex-grow-1">
                            <div className="d-flex justify-content-between mb-2">
                                <h6 className="mb-0">{EventTypeStrings[auction.eventType_ID]}</h6>
                                <div>
                                    {auction.eventStatus_ID === EventStatus.InProgress && (
                                        <LiveIcon style={{height: '20px', width: '50px'}} />
                                    )}
                                </div>
                            </div>
                            <h2>{auction.eventName}</h2>
                            <h3>{moment(auction.eventDateTime).format('dddd - MMMM DD, YYYY h:mma').toUpperCase()}</h3>
                            <span>{auction.eventDescription}</span>
                        </div>
                        <div className="align-self-end w-100">
                            <EventRegistration auction={auction} registerForEvent={handleRegisterForEvent} callback={getTodaysEvents} />
                        </div>
                    </Card>
                </Col>
            ))}
        </React.Fragment>


    )
}

const mapStateToProps = (state) => ({
    todaysEvents: state.events.todaysEvents,
})

const mapDispatchToProps = {
    getTodaysEvents
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaysEvents);