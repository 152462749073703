import moment from 'moment';

export const checkForLiveAuction = () => (dispatch, getState) => {
    const {main: { upcomingEvents }} = getState();

    var today = new Date();
    var todayUtc = moment.utc(today).local().format("llll").slice(0, -8);

    var startOfWeek = moment().startOf('isoWeek');
    var endOfWeek = moment().endOf('isoWeek');

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
        let formatDay = moment.utc(day).format('llll').slice(0, -8);
        days.push(formatDay);
        day = day.clone().add(1, 'd');
    }

    let arr = upcomingEvents;
    if (arr === undefined) {
        return false;
    } else {
        for (let i = 0; i < arr.length; i++) {
            if (days.includes(arr[i].eventDate)) {
                return true;
            } else if (days.slice(3).includes(todayUtc)) {
                return false;
            }
        }
    }
}
