import React from 'react';

export default () => {

    // setTimeout(function () {
    //    document.querySelector(".loader-wrapper").style = "display: none";
    // }, 1000);

    return (
        <div className="loader-wrapper">
            <div className="loader"></div>
        </div>
    )
};