import { createAuthRemoteCall } from '../../store/utils';
import * as actionTypes from './action-types';
import { logout, updateAuthorizationState, login } from '../authorization';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { updateAccountState } from '../account';
import { reset } from 'redux-form';
import { saveMarketingQuestionResponse } from '../settings';

export const createExternalUser = (data) => async (dispatch) => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.CREATE_EXTERNAL_USER, loading: actionTypes.REGISTER_BTN_LOADER }, api => api.register.createExternalUser, logout)(data));

  if (result.messages) {
    dispatch(updateRegisterState({ error: result.messages[0], loading_registered: true }))
  }

  if (!result.error) {
    dispatch(updateRegisterState({
      passwordRequest: true,
      emailAddress: data.email
    }));
    dispatch(saveMarketingQuestionResponse({
      user_ID: result.payload.userProfile.id,
      marketingQuestion_ID: result.payload.userProfile.marketingQuestion_ID,
      user_Name: result.payload.userProfile.userName,
      createdDateTime: result.payload.userProfile.lastLoginUTC,
      isArchived: false,
      archivedDateTime: null
    }))
  }
};

export const getCountries = () => async (dispatch, getState) => {
  if (getState().register.countries.length > 0) {
    return { payload: getState().register.countries };
  }
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_COUNTRIES }, 
      api => api.register.getCountries,
      logout
    )());
  return result;
}

export const resetPassword = (data) => async dispatch => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.RESET_PASSWORD }, api => api.register.resetPassword, logout)(data));

  if (result && !result.error) {
    dispatch(login({
      username: data.email,
      password: data.password,
    }));
    dispatch(updateAuthorizationState({ isGuest: false }));
    
    if (data.isCreate === undefined || data.isCreate === false) {
      toast.success(`Password Reset`);
      
      dispatch(push(data.callbackURL ? `${data.callbackURL.replace(/%2F/g,"/").replace(/%2B/g,"+")}` : '/'));
      
      dispatch(updateRegisterState({
        passwordRequest: false,
        isResetPassord: false,
        callbackURL: data.callbackURL,
      }));
    }
    else {
      dispatch(updateRegisterState({
        passwordRequest: false,
        isResetPassord: false,
        callbackURL: data.callbackURL,
      }));
      dispatch(push('/welcome-to-auctions'));
    }
  }

  if (result && result.error) {
    if (result.messages) {
      dispatch(updateRegisterState({ error: result.messages[0] }));
    } else {
      dispatch(updateRegisterState({ error: result.error }));
    }
  }
};

export const externalValidatePhoneRequest = data => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.VALIDATE_PHONE_REQUEST }, api => api.register.externalValidatePhoneRequest, logout)(data))

  if (!result.error || callback) {
    callback()
  }
}

export const externalValidatePhone = data => async (dispatch) => {
  return await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.VALIDATE_PHONE }, api => api.register.externalValidatePhone, logout)(data))
}

export const updateRegisterState = item => ({
  type: actionTypes.UPDATE_REGISTER_STATE,
  payload: { object: item }
});

export const requestPasswordReset = (data, callback) => async (dispatch, getState) => {
  const { register: { passwordRequest } } = getState();
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.REQUEST_RESET_PASSWORD }, api => api.register.requestPasswordReset, logout)(data));

  if (!result.error && passwordRequest) {
    dispatch(updateRegisterState({
      passwordRequest: false,
      isResetPassord: false,
      emailAddress: data.emailAddress
    }));
  }

  if (!result.error && callback) {
    callback();
  }

  if (!result.error) {
    let promise = new Promise((resolve) => {
      resolve(dispatch(updateRegisterState({
        passwordRequest: true,
        isResetPassord: true,
        emailAddress: data.emailAddress
      })));
    })

    promise.then(() => {
      if (passwordRequest) {
        toast.success(`Email Resent`);
      }
    })
  }
};

export const requestPasswordResetWelcome = (data, callback) => async (dispatch, getState) => {
  const { router: { location: { pathname } }, register: { passwordRequest } } = getState();
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.REQUEST_RESET_PASSWORD_WELCOME }, api => api.register.requestPasswordResetWelcome, logout)(data));
  if (!result.error && !pathname.includes('welcome') && !pathname.includes('reset')) {
    if (data.callbackURL !== undefined && data.callbackURL !== null) {
      dispatch(push(decodeURIComponent(data.callbackURL)));
    }
    else {
      dispatch(push('/'));
    }
  }

  if (!result.error && callback) {
    callback();
  } else if (!result.error) {
    let promise = new Promise((resolve) => {
      resolve(dispatch(updateRegisterState({
        passwordRequest: true,
        isResetPassord: true,
        emailAddress: data.emailAddress
      })))
    })

    promise.then(() => {
      if (passwordRequest) {
        toast.success(`Email Resent`);
      }
    })
  }
};

export const registerUserAccount = data => async dispatch => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.REGISTER_USER_ACCOUNT }, api => api.register.registerUserAccount, logout)(data.parameters));
  if (!result.error) {
    toast.success(`Account Registered Successfully`);
    return result.payload.account_ID;
  }
  else {
    if (result.messages) {
      toast.error(result.messages[0], {
        position: "top-center",
        auctoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false
      })
    }
    else {
      toast.error('Error occured please contact support', {
        position: "top-center",
        auctoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false
      })
    }
    return false;
  }
}

export const createAccount = (data, callback) => async dispatch => {
  const params = {
    isBusinessAccount: data.companyAccount,
    businessName: data.companyName || null,
    businessTitle: data.companyTitle || null,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    suffix: data.suffix,
    country_ID: data.physical_country_ID.id,
    addressLine1: data.addressLine1 && (data.addressLine1.value || data.addressLine1),
    addressLine2: (data.addressLine2 && data.addressLine2) || null,
    city: data.physicalCity,
    state: data.physicalState && (data.physicalState.label || data.physicalState),
    postalCode: data.physicalPostalCode,
    hasAccount: false,
    driverLicenseNumber: data.driverLicenseNumber ? data.driverLicenseNumber : "",
    driverLicenseCountry_ID: data.driverLicenseCountry_ID ? data.driverLicenseCountry_ID.id : 0,
    driverLicenseState:  data.driverLicenseState ? data.driverLicenseState.value : ""
  }

  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.CREATE_ACCOUNT, loading: actionTypes.BTN_SPINNER }, api => api.register.createAccount, logout
  )(params));

  if (result && !result.error) {
    dispatch(updateAccountState({
      step: 2,
    }));
    dispatch(updateAuthorizationState({hasAccount: true}));
  } else {
    toast.error(`${result && result.messages[0]}`, {
      position: "top-center",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false
    });
    dispatch(updateRegisterState({
      btn_spinner: true,
    }));
  }

  if (!result.error && callback) {
    callback();
  }
}

export const validatePhoneRequest = data => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.REGISTRATION_VALIDATE_PHONE_REQUEST, loading: actionTypes.LOADING_VALIDATE_PHONE }, api => api.register.validatePhoneRequest, logout
  )(data))
  if (!result.error && callback) {
    callback();
  }
}

export const validatePhone = data => async (dispatch, getState) => {
  const { userAccount: { country_ID, step } } = getState();
  const isInternational = country_ID !== 0;
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.REGISTRATION_VALIDATE_PHONE, loading: actionTypes.LOADING_VALIDATE_PHONE }, api => api.register.validatePhone, logout
  )(data));

  if (result && result.payload.isPhoneVerified) {
    await dispatch(updateAccountState({
      ...result.payload,
      step: isInternational ? 4 : step,
    }));
		dispatch(reset('verifyPhoneForm'));
  } else {
    dispatch(updateAccountState({
      isPhoneVerifiedError: 'Unable to Validate Code'
    }));
    dispatch(reset('verifyPhoneForm'));
  }
	return result;
}

export const validateEmail = data => async dispatch => {
  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.VALIDATE_EMAIL, loading: actionTypes.VALIDATE_EMAIL_LOADING }, api => api.register.validateEmail, logout
  )(data));

  if (result && result.payload.validateEmail.success) {
    dispatch(updateRegisterState({
      passwordRequest: false,
      isResetPassord: false,
      callbackURL: data.callbackURL,
      isCreateAccount: true,
    }));

    dispatch(login({
      username: data.email,
      password: data.password,
    }, dispatch(push('/welcome-to-auctions'))));

    dispatch(updateAuthorizationState({ isGuest: false }));
  } else if (result && !result.payload.validateEmail.success) {
    window.clarity("set", "validation", "validationFailed");
    dispatch(updateRegisterState({ error: result.payload.validateEmail.errorDescription, validate_email_loading: false }));
  }

  return result;
}

export const validateInvitedUser = data => async dispatch => {

  const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.VALIDATE_INVITED_USER }, api => api.register.validateInvitedUser, logout
  )(data));

	if (result && !result.error) {
		
		dispatch(updateRegisterState({
      passwordRequest: false,
      isResetPassord: false,
      isCreateAccount: false,
    }));

		dispatch(login({
      username: data.email,
      password: data.password,
    }, dispatch(push('/'))));

    dispatch(updateAuthorizationState({ isGuest: false }));
		
	}
}