import { addMinutes } from "date-fns";

import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";

const preserve = (status, login = {}) => {
	const { expires_in, refresh_token_expires_in } = login;
	const expiryDate = addMinutes(new Date(), expires_in);
	const refreshExpiryDate = addMinutes(new Date(), refresh_token_expires_in);
	const latitude = null;
	const longitude = null;
	const state = { status, ...login, expiryDate, refreshExpiryDate, latitude, longitude };
	localStorage.setItem("auctionsAuthorization", JSON.stringify(state));

	return state;
};

const initialState = {
	content: {
		tenantRegistrationRequiresCreditCardAuth: false,
		registrationStepThree: "",
	},
}

export default createReducer(
	initialState,
	{
		[actionTypes.LOGIN]: (state, action) => preserve(true, action.payload),
		[actionTypes.REFRESH]: (state, action) => preserve(true, action.payload),
		[actionTypes.HANDOFF]: (state, action) => preserve(true, action.payload),
		[actionTypes.PROXY]: (state, action) => preserve(true, action.payload),
		[actionTypes.LOGOUT]: () => preserve(false),
		[actionTypes.SET_ERROR]: (state, action) => ({ ...state, error: action.error }),
		[actionTypes.UPDATE_AUTHORIZATION_STATE]: (state, action) => ({ ...state, ...action.payload.object }),
		[actionTypes.GET_PAGE_CONTENT]: (state, action) => ({ ...state, content: { ...state.content, ...action.payload } }),
		[actionTypes.RESET_AUTH_ERROR]: (state, action) => ({ ...state, error: null }),
	}
);
