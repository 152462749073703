export const UPDATE_DOCUMENT_STATE = 'innovA/documents#updateDocumentState';
export const UPLOAD_DOCUMENT = 'innovA/documents#uploadDocument';
export const LOADING_UPLOAD_DOCUMENT = 'innovA/documents#loadingUploadDocument';
export const GET_DOCUMENTS = 'innovA/documents#getDocuments';
export const LOADING_DOCUMENTS = 'innovA/documents#loadingDocuments';
export const REQUEST_DOCUMENT_DOWNLOAD = 'innovA/documents#requestDocumentDownload';
export const DOCUMENT_DOWNLOAD = 'innovA/documents#documentDownload';
export const REQUEST_USER_DOCUMENT_DOWNLOAD = 'innovA/documents#requestUserDocumentDownload';
export const DELETE_DOCUMENT = 'innovA/documents#deleteDocument';
export const UPDATE_SPINNER_STATE = 'innovA/documents#updateSpinnerState';
