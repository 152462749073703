export const LOADING_MAIN = 'innovA/main#loadingMain';
// export const GET_FEATURED_ITEMS = 'innovA/main#getFeaturedItems';
export const GET_RECENT_ITEMS = 'innovA/main#getRecentItems';
export const GET_UPCOMMING_AUCTIONS = 'innovA/main#getUpcommingAuctions';
export const GET_CATEGORIES_WITH_FAMLILIES  = 'innovA/main#getCategoriesWithFamilies';
export const GET_FEATURED_CATEGORIES = 'innovA/main#getFeaturedCategories';
export const LOADING_UPCOMING_AUCTIONS = 'innovA/main#loadingUpcomingAuctions';
export const GET_AUCTION_BLOCK = 'innovA-acutions/main#getAuctionBlock';
export const SUBSCRIBE_NEWSLETTER = 'innovA/main#subscribeNewsletter';
export const SET_IS_NAV_EVENT = 'innovA/main#setIsNavEvent';
export const UPDATE_MAIN_STATE = 'innovA/main#updateMainState';
export const GET_CONTENT_DOCUMENTS = 'innovA/main#getContentDocuments';
export const CREATE_CONTACT_US_LEAD = 'innovA/main#contactUsLead'
