import { createAuthRemoteCall } from "../../store/utils";
import * as actionTypes from "./action-types";
import { logout } from "../authorization";

export const getMarketingQuestions = (params) => async (dispatch) =>
  await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_MARKETING_QUESTIONS },
      (api) => api.settings.getMarketingQuestions,
      logout
    )(params)
  );

export const saveMarketingQuestionResponse = (data) => async (dispatch) =>
  await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.SAVE_MARKETING_QUESTION_RESPONSE },
      (api) => api.settings.saveMarketingQuestionResponse,
      logout
    )(data)
  );

export const getStockNumberUsed = (params) => async (dispatch, getState) => {
  if (getState().settings.loaded) {
    return { payload: { isStockNumberUsed: getState().settings.isStockNumberUsed}}
  }  
  const result = await dispatch(
    createAuthRemoteCall(
      { fetch: actionTypes.GET_STOCK_NUMBER_USED, loading: actionTypes.LOADING_STOCK_NUMBER },
      (api) => api.settings.getStockNumberUsed,
      logout
    )(params)
  );
  return result
}
