import { get, update,  } from './common';

export default {
    getUserInformation: async (params, token) => get(`/v1/Auction/getUserInformation`, token),
    getUserOfferHistory: async (params, token) => get(`/v1/Auction/GetUserOfferHistory?offerType=${params.offerType}`, token),
    updateUserAccount: async (params, token) => update(`/v1/Auction/UpdateUserAccount`, params, token),
    setExternalUserPermissions: async (params, token) => update(`/v1/Auction/SetExternalUserPermissions`, params, token),
    getListedItems: async (params, token) => get(`/v1/Auction/GetListedItems?statusFilter=${params.statusFilter}`, token),
    getSoldItems: async (params, token) => get(`/v1/Auction/GetSoldItems`, token),
    getUserWonPurchaseHistory: async (params, token) => get(`/v1/Auction/GetWonPurchased`, token),
    getUserLostItemHistory: async (params, token) => get(`/v1/Auction/GetLostItems`, token),
    getExternalUserPermissions: async (params, token) => get(`/v1/Auction/GetUserAuctionPermissions`, token),
    getInvoices: async (params, token) => get(`/v1/auction/GetInvoices`, token),
    getInvoiceHTML: async (params, token) => get(`/v1/Account/GetInvoiceHTMLObject?Invoice_ID=${params.invoice_ID}`, token),
    getInvoicePDF: async (params, token) => get(`/v1/Account/GetInvoicePDF?Invoice_ID=${params.invoice_ID}`, token),
    getReleaseTicketHTML: async (params, token) => get(`/v1/Account/GetReleaseTicketHTMLObject?Item_ID=${params.item_ID}`, token),
    getReleaseTicketPDF: async (params, token) => get(`/v1/Account/GetReleaseTicketPDF?Item_ID=${params.item_ID}`, token),
    updatePhysicalAddress: async (params, token) => update(`/v1/Auction/UpdatePhysicalAddress`, params, token),
    updatePhysicalAddressV2: async (params, token) => update(`/v1/Auction/UpdatePhysicalAddress_V2`, params, token),
    updateBillingAddress: async (params, token) => update(`/v1/Auction/UpdateBillingAddress`, params, token),
    updateBillingAddressV2: async (params, token) => update(`/v1/Auction/UpdateBillingAddress_V2`, params, token),
		getHandOffToken: async (params, token) => get(`/v1/Settings/GetHandOff`, token),
		getRegisteredAuctions: async (params, token) => get(`/v1/Auction/GetRegisteredAuctions`, token),
    getTaxExemptionTypes: async (params, token) => get(`/v1/Settings/GetTaxExemptionType`, token),
    getContactInfo: async (params, token) => get(`/v1/Auction/GetContactInfo`, token),
    updateContactBidderTag: async (params, token) => update(`/v1/Auction/updateContactBidderTag`, params, token),
}