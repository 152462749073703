import React, { lazy, useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import {
  setBoolean,
  setCategories,
  getInventorySearchCriteria,
  clearFilters,
  getInventory,
  setFilteredItem,
} from "../../../reducers/search";
import { checkForStringValue } from "../../../utils/check-filter-for-value";
import { setIsNavEvent } from "../../../reducers/main";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import persistSearch from "../../../utils/persistSearch";
import checkForCriteriaKeys from "../../../utils/check-for-criteria-keys";
import { reset } from "redux-form";
import { errorRedirect } from "../../../utils/error-redirect";
import { updateAuthorizationState } from "../../../reducers/authorization";
import clearAllParams from "../../../utils/clear-all-params";
import _ from "lodash";

const NavBar = lazy(() => import("./common/navbar"));
const SideBar = lazy(() => import("./common/sidebar"));
const TopBar = lazy(() => import("./common/topbar"));
const CategoryNav = lazy(() => import("./common/cat-navbar"));

const HeaderOne = (props) => {
  const {
    push,
    setBoolean,
    setCategories,
    searchCategories,
    getInventorySearchCriteria,
    featuredCategories,
    clearFilters,
    checkForStringValue,
    location,
    persistSearch,
    checkForCriteriaKeys,
    reset,
    setIsNavEvent,
    isNavEvent,
    errorRedirect,
    updateAuthorizationState,
    auctionBlock,
    getInventory,
    categoriesFilter,
    clearAllParams,
    setFilteredItem,
    filteredItems,
    content,
    event,
  } = props;

  const [tenantName, setTenantName] = useState("");
  const [tenantLogo, setTenantLogo] = useState("");
  const [tenantPhone, setTenantPhone] = useState("");

  const [scrolled, setScrolled] = useState(false);

  useLayoutEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  useEffect(() => {
    if (content) {
      setTenantName(content.tenantName);
      setTenantLogo(content.theme.logo1);
      setTenantPhone(content.tenantPhone);
    }
  }, [content]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let _isMounter = true;
    errorRedirect();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
    return () => (_isMounter = false);
  }, []);

  const showPosition = (position) => {
    updateAuthorizationState({ latitude: position.coords.latitude, longitude: position.coords.longitude });
  };

  const openNav = () => {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  };

  // const renderLiveBanner = () => {
  //   if (isAuctionLive) {
  //     return (
  //       <Link to="/live-auction" className="live-auction-button">
  //         LIVE&nbsp; <span className="d-none d-sm-block d-md-block">AUCTION</span>
  //       </Link>
  //     );
  //   }

  //   return null;
  // };

  return (
    <header id="sticky" className={["sticky fixed", scrolled ? "shadow" : ""].join(" ")}>
      {/*Top Header Component*/}
      <TopBar
        {...{
          reset,
          push,
          clearFilters,
          setFilteredItem,
          filteredItems,
          categoriesFilter,
          getInventory,
          persistSearch,
          tenantPhone,
        }}
      />

      <div className="main-header-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main-menu">
                <div className="menu-left">
                  <div className="navbar display-none d-xl-none">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => openNav()}>
                      <div className="bar-style">
                        {" "}
                        <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                      </div>
                    </a>
                    <SideBar
                      {...{
                        checkForStringValue,
                        location,
                        reset,
                        persistSearch,
                        push,
                        clearFilters,
                        checkForCriteriaKeys,
                        getInventorySearchCriteria,
                      }}
                    />
                  </div>
                  <div>
                    {(content && !content.websiteLogoLink) && (
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        <img
                          className="img-fluid header-logo"
                          style={{ imageRendering: "crisp-edges" }}
                          src={tenantLogo}
                          alt={tenantName}
                        />
                      </Link>
                    )}
                    {(content && content.websiteLogoLink) && (
                      <a href={content.websiteLogoLink} >
                        <img
                          className="img-fluid header-logo"
                          style={{ imageRendering: 'crisp-edges' }}
                          src={tenantLogo}
                          alt={tenantName}
                        />
                      </a>
                    )}

                    {/* {renderLiveBanner()} */}
                    {/* {auctionBlock.auctionStarted && (
										<Link to="/live-auction" className="live-auction-button">
											LIVE&nbsp; <span className="d-none d-sm-block d-md-block">AUCTION</span>
										</Link>
									)} */}
                    {/* {auctionBlock.auctionStarted &&
                                    <Link to="/simulcast" className="live-auction-button">LIVE&nbsp; <span className="d-none d-sm-block d-md-block">AUCTION</span></Link>} */}
                  </div>
                </div>
                <div className="menu-right">
                  <NavBar
                    {...{
                      setIsNavEvent,
                      isNavEvent,
                      categoriesFilter,
                      getInventory,
                      clearAllParams,
                      location,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="display-none d-xl-block">
        <CategoryNav
          {...{
            push,
            setBoolean,
            setCategories,
            getInventorySearchCriteria,
            searchCategories,
            featuredCategories,
            clearFilters,
            persistSearch,
            getInventory,
            categoriesFilter,
            clearAllParams,
            location,
          }}
        />
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  location: state.router.location,
  searchCategories: state.search.categories,
  featuredCategories: state.main.featuredCategories,
  hasConsented: state.authorization.hasConsented,
  isNavEvent: state.main.isNavEvent,
  lat: state.authorization.latitude,
  lon: state.authorization.longitude,
  auctionBlock: state.main.upcomingAuctionBlock,
  categoriesFilter: state.search.categoriesFilter,
  filteredItems: state.search.filteredItems,
  content: state.authorization.content,
  event: state.liveAuction.event,
});

const mapDispatchToProps = {
  setBoolean,
  setCategories,
  getInventorySearchCriteria,
  push,
  clearFilters,
  checkForStringValue,
  persistSearch,
  reset,
  checkForCriteriaKeys,
  setIsNavEvent,
  errorRedirect,
  updateAuthorizationState,
  getInventory,
  clearAllParams,
  setFilteredItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOne);
