import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';
import {APP_CLEAR_STORE} from '../authorization/action-types';

const initialState = {
    comparedItems: [],
};

export default createReducer(initialState, {
    [APP_CLEAR_STORE]: () => ({...initialState}),
    [actionTypes.ADD_COMPARE_ITEM]: (state, action) => {
        return {
            ...state, comparedItems: state.comparedItems.concat(action.payload)
        }
    },
    [actionTypes.REMOVE_COMPARED_ITEM]: (state, action) => ({...state, comparedItems: state.comparedItems.filter(item => item.item_ID !== action.payload.item_ID)}),
    [actionTypes.UPDATE_COMPARE_ITEM]: (state, action) => {
        const temp = state.comparedItems;
        temp.forEach(data => {
            if (data.item_ID === action.payload.item_ID) {
                data.showMore = action.payload.showMore
            }
        });
        return {
            ...state, comparedItems: [...temp]
        }
    },
    [actionTypes.UPDATE_COMPARED_LIST]: (state, action) => ({...state, comparedItems: action.payload}),
});
