import { createAuthRemoteCall } from '../../store/utils';
import * as actionTypes from './action-types';
import { logout } from '../authorization';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// export const getFeaturedItems = () => createAuthRemoteCall(
//     { fetch: actionTypes.GET_FEATURED_ITEMS }, api => api.main.getFeaturedItems, logout)();

export const getRecentItems = () => createAuthRemoteCall(
    { fetch: actionTypes.GET_RECENT_ITEMS }, api => api.main.getPreviouslyViewedItems, logout)()

export const getFeaturedCategories = () => createAuthRemoteCall(
    { fetch: actionTypes.GET_FEATURED_CATEGORIES }, api => api.main.getFeaturedCategories, logout)()

export const getUpcomingAuctionsPreview = () => createAuthRemoteCall(
    { fetch: actionTypes.GET_UPCOMMING_AUCTIONS, loading: actionTypes.LOADING_UPCOMING_AUCTIONS }, api => api.main.getUpcomingAuctionsPreview, logout)()

export const getCategoriesWithFamilies = () => createAuthRemoteCall(
    { fetch: actionTypes.GET_CATEGORIES_WITH_FAMLILIES }, api => api.main.getCategoriesWithFamilies, logout)()

export const getAuctionBlock = () => createAuthRemoteCall(
    {fetch: actionTypes.GET_AUCTION_BLOCK}, api => api.main.getAuctionBlock, logout)()

export const getContentDocuments = () => createAuthRemoteCall(
    {fetch: actionTypes.GET_CONTENT_DOCUMENTS}, api => api.main.getContentDocuments, logout)()

export const subscribeToNewsletter = params => async dispatch => {
    const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.SUBSCRIBE_NEWSLETTER }, api => api.main.subscribeToNewsletter, logout)(params));

    if (!result.error) {
        toast.success(`Subscribed to newsletter`);
    }
    else {
        toast.error('Unable to subscribe to newsletter');
    }
}

export const createContactUsLead = params => async dispatch => {
    const result = await dispatch(createAuthRemoteCall(
    { fetch: actionTypes.CREATE_CONTACT_US_LEAD }, api => api.main.createContactUsLead, logout)(params));

    if (!result.error) {
        toast.success(`Thank you, someone will be in contact with you soon`);
    }
    else {
        toast.error('Unable to send contact us');
    }
}

export const updateMainState = item => ({
    type: actionTypes.UPDATE_MAIN_STATE,
    payload: {object: item}
})

export const setIsNavEvent = item => ({
    type: actionTypes.SET_IS_NAV_EVENT,
    payload: {object: item}
}) 
