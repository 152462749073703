import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { logout, reloadMain } from "../../reducers/authorization";
import LoginRegister from "../account/login/";

const InactivityMonitor = ({ reset, logout, reloadMain }) => {
	const [inactivityModal, setInactivityModal] = useState(true);
	const [counter, setCounter] = useState(10);
	const [isLoggedIn, setIsLoggedIn] = useState(true);

	useEffect(() => {
		async function handleLogout() {
			setIsLoggedIn(false);
			await logout();
			await reloadMain();
			reset();
		}

		if (counter === 0) {
			handleLogout();
			return;
		}

		const timer = setTimeout(() => {
			setCounter(counter - 1);
		}, 1000);
		return () => clearTimeout(timer);
	}, [counter]);

	const handleModal = () => {
		setInactivityModal(!inactivityModal);
	};

	return (
		<React.Fragment>
			<Modal isOpen={inactivityModal} className="modal-dialog-centered">
				{isLoggedIn ? (
					<React.Fragment>
						<ModalBody>
							<h2 className="primary-color-text">Are you still there?</h2>
							<p className="m-0" style={{ color: "#000" }}>
								You will be logged out due to inactivity in {counter} seconds
							</p>
						</ModalBody>
						<ModalFooter className="justify-content-end">
							<Button className="primary-color-btn" onClick={reset}>
								STAY LOGGED IN
							</Button>
						</ModalFooter>
					</React.Fragment>
				) : (
					<React.Fragment>
						<ModalBody>
							<h2 className="primary-color-text">You have been logged out due to inactivity</h2>
						</ModalBody>
						<ModalFooter className="justify-content-end">
							<Button onClick={handleModal}>CLOSE</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

const mapStateToProps = () => ({ router: { location } }) => ({
	location,
});

const mapDispatchToProps = {
	logout,
	reloadMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(InactivityMonitor);
