import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";
import { APP_CLEAR_STORE } from "../authorization/action-types";

const initialState = {
  item: {
    meterDescription: "",
    details: "",
    documents: [],
    settings: {},
    loadOut: {},
    loadingEventRegister: true,
    loaded_preBid: true,
    hasPrebid: false,
    preBidAmount: "",
    prebidStatus_ID: null,
  },
  gallery: { loaded_gallery: false },
  message: "",
  preBidResult: "",
  inspectionDetails: {},
  makeOffer: { loaded_offer: true, offerError: false, offerErrorMessage: "" },
  gettingInspectionReport: false,
  report: null,
  loaded: true,
  termsAndConditions: "",
};

export default createReducer(initialState, {
  [APP_CLEAR_STORE]: () => ({ ...initialState }),
  [actionTypes.LOADING_ITEM]: (state) => ({ ...state, loaded: false }),
  [actionTypes.LOADING_PRE_BID]: (state) => ({ ...state, item: { ...state.item, loaded_preBid: false } }),
  [actionTypes.LOADING_GALLERY]: (state) => ({ ...state, gallery: { loaded_gallery: false } }),
  [actionTypes.LOADING_OFFER]: (state) => ({ ...state, makeOffer: { ...state.makeOffer, loaded_offer: false } }),
  [actionTypes.GET_PHOTO_GALLERY]: (state, action) => {

    return {
      ...state,
      gallery: {
        photos: action.payload.photos.map(x => ({
          ...x,
          type: x.type_ID === 1 ? "image" : "video",
        })),
        loaded_gallery: true,
      }
    }
  },
  // [actionTypes.GET_PHOTO_GALLERY]: (state, action) => ({ ...state, gallery: { ...action.payload, loaded_gallery: true } }),
  [actionTypes.GET_ITEM]: (state, action) => ({ ...state, item: { ...state.item, ...action.payload.item }, loaded: true }),
  [actionTypes.PRE_BID]: (state, action) => {

    let showCurrentBid = action.payload.isCurrentBidDisplayed ? action.payload.isCurrentBidDisplayed : state.item.showCurrentBid;
    let price = action.payload.price ? action.payload.price : state.item.price;

    return {
      ...state,
      item: {
        ...state.item,
        ...action.payload,
        showMessage: true,
        loaded_preBid: true,
        showCurrentBid: showCurrentBid,
        price: showCurrentBid ? price : state.item.price
      },
    };
  },
  [actionTypes.BUY_NOW]: (state, action) => ({ ...state, ...action.payload }),
  [actionTypes.UPDATE_STATE_VALUE]: (state, action) => {
    return { ...state, ...action.payload.object };
  },
  [actionTypes.UPDATE_STATE_OFFER]: (state, action) => ({ ...state, makeOffer: { ...state.makeOffer, ...action.payload.object } }),
  [actionTypes.MAKE_OFFER]: (state, action) => ({ ...state, makeOffer: { ...state.makeOffer, ...action.payload, loaded_offer: true } }),
  [actionTypes.GET_ITEM_INSPECTION]: (state, action) => ({ ...state, inspectionDetails: action.payload }),
  [actionTypes.UPDATE_AUCTION_ITEM_SETTINGS]: (state, action) => ({ ...state, item: { ...state.item, settings: { ...state.item.settings, ...action.payload.object } } }),
  [actionTypes.GET_LIVE_AUCTION_ITEM]: (state, action) => ({ ...state, item: { ...state.item, ...action.payload } }),
  [actionTypes.UPDATE_INSTA_BID_TOGGLE]: (state, action) => ({ ...state, item: { ...state.item, ...action.payload.object } }),
  [actionTypes.UPDATE_ITEM_STATE]: (state, action) => ({ ...state, item: { ...state.item, ...action.payload.object } }),
  [actionTypes.RESET_STATE]: () => {
    return initialState;
  },
  [actionTypes.UPDATE_AUCTION_BIDDERS_STATE]: (state, action) => {
    const temp = state.item.bidders;

    temp.forEach((data) => {
      if (data.name === action.payload.name) {
        data.isOpen = !action.payload.isOpen;
      }
    });
    return {
      ...state,
      item: { ...state.item, bidders: temp },
    };
  },
  [actionTypes.UPDATE_AUCTION_BIDDERS_STATE]: (state, action) => {
    const temp = state.item.bidders;

    temp.forEach((data) => {
      if (data.name === action.payload.name) {
        data.isOpen = !action.payload.isOpen;
      }
    });
    return {
      ...state,
      item: { ...state.item, bidders: temp },
    };
  },
  [actionTypes.UPDATE_AUCTION_VIEWERS_STATE]: (state, action) => {
    const temp = state.item.viewers;

    temp.forEach((data) => {
      if (data.name === action.payload.name) {
        data.isOpen = !action.payload.isOpen;
      }
    });
    return {
      ...state,
      item: { ...state.item, viewers: temp },
    };
  },
  [actionTypes.UPDATE_AUCTION_REVISITERS_STATE]: (state, action) => {
    const temp = state.item.revisiters;

    temp.forEach((data) => {
      if (data.name === action.payload.name) {
        data.isOpen = !action.payload.isOpen;
      }
    });
    return {
      ...state,
      item: { ...state.item, revisiters: temp },
    };
  },
  [actionTypes.UPDATE_DOCUMENT_SPINNER_STATE]: (state, action) => {
    const temp = [...state.item.documents];
    temp.forEach((item) => {
      if (item.id === action.payload.object.id) {
        if (item.spinner === undefined) {
          item.spinner = true;
        } else {
          item.spinner = !item.spinner;
        }
      }
    });
    return {
      ...state,
      item: { ...state.item, documents: temp },
    };
  },
  [actionTypes.GET_ITEM_INSPECTION_REPORT]: (state, action) => ({ ...state, gettingInspectionReport: false, report: action.file }),
  [actionTypes.GET_INSPECTION_REPORT]: (state, action) => ({ ...state, gettingInspectionReport: false, report: action.file }),
  [actionTypes.LOADING_EVENT_REGISTER]: (state) => ({ ...state, item: { ...state.item, loadingEventRegister: false } }),
  [actionTypes.GET_TERMS_AND_CONDITIONS]: (state, action) => {
    return {
      ...state,
      termsAndConditions: action.payload.termsAndConditions,
    };
  },
  [actionTypes.UPDATE_ITEM_MESSAGE]: (state, action) => {
    return {
      ...state,
      item: {
        ...state.item,
        ...action.payload,
        showMessage: true,
      }
    }
  }
});
