import { push } from "connected-react-router";

// import { push } from 'react-router-redux';

const parseQueryString = paramsString => JSON.parse(paramsString ?
  // eslint-disable-next-line no-div-regex
  '{"' + paramsString.replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}' : '{}',
);

const generateQueryString = paramsObject => `?${Object.keys(paramsObject).map(param => `${param}=${paramsObject[param]}`).join('&')}`;

export default (params, paramValue) => (dispatch, getState) => {
  const hasAmp = Object.values(params)[0].includes('&');
  const hasQuotes = Object.values(params)[0].includes('"')

  const objValue = {
    [Object.keys(params)[0]]: hasAmp || hasQuotes ? Object.values(params)[0].replace(/&/g, "and").replace(/"/g, "'") : Object.values(params)[0],
  }

  const { router: { location } } = getState();
  const checkForObject = !location.search.includes('=');
  const searchParams = parseQueryString(checkForObject ? '' : location.search || '');
  const searchObj = Object.keys(searchParams);
  const searchValues = Object.values(searchParams);

  const checkForKeys = value => {
    for (let i = 0; i < searchObj.length; i++) {
      if (searchObj[i] === value) {
        return true;
      }
    }
  }

  const getObjValue = value => {
    for (let i = 0; i < searchObj.length; i++) {
      if (value === searchObj[i]) {
        for (let x = 0; x < searchValues.length; i++) {
          return searchValues[i]
        }
      }
    }
  }

    let newObject = {}

    if (checkForKeys(paramValue) && paramValue !== 'page_number' && paramValue !== 'status') {
      newObject = {
        [paramValue]: Object.values(objValue)[0] + '|' + getObjValue(paramValue)
      }
    } else if (paramValue) {
      newObject = {
        [paramValue]: Object.values(objValue)[0]
      }
    }

    let querySearch = {};

    const findDuplicates = data => {
      let newObjValues = Object.values(data);
      let newObjValuesArr = newObjValues[0].split("|");
      const uniqueNames = newObjValuesArr.filter((val,id,array) => array.indexOf(val) === id);

      let deDupedObject = {
        [paramValue]: uniqueNames.join("|")
      };

      return deDupedObject;
  }

    querySearch = Object.assign(searchParams, findDuplicates(newObject));


    const query = generateQueryString(querySearch);
    let path = `${location.pathname}${query}`;

    if (location.pathname !== '/search') {
      path = `/search${query}`
    }
    dispatch(push(path));    
};