import { get, update, create, remove } from './common';

export default {
    // addUserPaymentMethod: async (params, token) => create(`/v1/Payment/AddCustomerPaymentMethod`, params, token),
    editUserPaymentMethod: async (params, token) => update(`/v1/Payment/UpdateCustomerPaymentProfile`, params, token),
    getUserPaymentMethods: async (params, token) => get(`/v1/Payment/GetCustomerProfile?profileID=${params.paymentProfile_ID}`, token),
    deleteUserPaymentMethod: async (params, token) => remove(`/v1/Payment/DeleteCustomerPaymentMethod`, params, token),
    createCustomerPaymentProfile: async (params, token) => get(`/v1/Payment/CreateCustomerProfile`, token),
    chargeCustomerPaymentProfile: async (params, token) => create(`/v1/Payment/ChargeCustomerPaymentProfile`, params, token),
    payInvoice: async (params, token) => create(`/v1/Payment/PayInvoice`, params, token),
    getSavedPaymentMethods: async (params, token) => get(`/v1/Payment/GetSavedPaymentMethods`, token),
    addCustomerPaymentMethod: async (params, token) => create(`/v1/Payment/AddCustomerPaymentMethod`, params, token),
    payInvoicecc: async (params, token) => create(`/v1/Payment/PayInvoiceCC`, params, token),
    creditCardDeposit: async (params, token) => create(`/v1/Payment/CreditCardDeposit`, params, token),
    savedMethodDeposit: async (params, token) => create(`/v1/Payment/SavedMethodDeposit`, params, token),
    getSurcharge: async (params, token) => get(`/v1/Payment/GetSurcharge?amount=${params.amount}&isVirtual=true`, token),
}