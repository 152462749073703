import authorization from './authorization';
import events from './events';
import search from './search';
import register from './register';
import watchlist from './watchlist';
import auction from './auction';
import streets from './streets';
import main from './main';
import account from './account';
import payment from './payment';
import documents from './documents';
import settings from './settings';
import content from "./content";

export default {
    authorization,
    events,
    search,
    register,
    watchlist,
    auction,
    streets,
    main,
    account,
    payment,
    documents,
    settings,
		content,
};