import React from "react";
import { Button } from "reactstrap";


const RegistrationButton = ({ isRegistered, onRegisterClick, isGuest, setLoginModal, loginModalOpen }) => (
    <Button
        className={`mb-3 ${isRegistered ? 'alert-success' : 'alert-secondary'} w-100`}
        role="alert"
        style={{ cursor: "pointer" }}
        title={isRegistered ? "Click to unregister." : "Click to register"}
        onClick={isRegistered ? onRegisterClick : (isGuest ? () => setLoginModal(!loginModalOpen) : onRegisterClick)}
    >
        <i className={`fa ${isRegistered ? 'fa-check-circle good' : 'fa-calendar boldLinkButton'} fa-2x`}>
            <span
                style={{
                    fontFamily: "MyriadReg",
                    fontSize: "14px",
                    marginLeft: isRegistered ? "10px" : "15px",
                    textTransform: "uppercase",
                    verticalAlign: "middle",
                }}
            >
                {isRegistered ? 'registered' : 'register'}
            </span>
        </i>
    </Button>
);

export default RegistrationButton;
