export const GET_ITEM = 'innovA/auction#getItem';
export const GET_PHOTO_GALLERY = 'innovA/auction#getPhotoGallery';
export const LOADING_GALLERY = 'innovA/auction#loadingGallery';
export const LOADING_ITEM = 'innovA/auction#loadingItem';
export const PRE_BID = 'innovA/auction#preBid';
export const UPDATE_STATE_VALUE = 'innovA-auction/auction#updateStateValue';
export const BUY_NOW = 'innovA/auction#buyNow';
export const UPDATE_STATE_OFFER = 'innovA-auction/auction#updateStateOffer';
export const MAKE_OFFER = 'innovA/auction#makeOffer';
export const LOADING_OFFER = 'innovA/auction#loadingOffer';
export const GET_ITEM_INSPECTION = 'innovA/auction#getItemInspection';
export const UPDATE_AUCTION_ITEM_SETTINGS = 'innovA-auction/auction#updateAuctionItemSettings';
export const GET_LIVE_AUCTION_ITEM = 'innovA/auction#getLiveAuctionItem';
export const UPDATE_INSTA_BID_TOGGLE = 'innovA/auction#udpateInstaBidToggle';
export const UPDATE_ITEM_STATE = 'innovA/auction#updateItemState';
export const UPDATE_AUCTION_BIDDERS_STATE = 'innovA/auction#updateAuctionBiddersState';
export const UPDATE_AUCTION_VIEWERS_STATE = 'innovA/auction#updateAuctionViewersState';
export const UPDATE_AUCTION_REVISITERS_STATE = 'innovA/auction#updateAuctionRevisitersState';
export const LOADING_PRE_BID = 'innovA/auction#loadingPrebid';
export const UPDATE_DOCUMENT_SPINNER_STATE = 'innovA/auction#updateDocumentSpinnerState';
export const GET_ITEM_INSPECTION_REPORT = 'innovA/auction#getItemInspectionReport';
export const GET_INSPECTION_REPORT = 'innovA/auction#getInspectionReport';
export const RESET_STATE = 'innovA/auction#resetState';
export const LOADING_EVENT_REGISTER = 'innovA/auctions#loadingEventRegister';
export const CHECK_IF_AUCTION_IS_LIVE = 'innovA/auctions#isAuctionIsLive';
export const GET_HANDOFF_TOKEN = 'innovA/auctions#getHandoffToken';
export const GET_TERMS_AND_CONDITIONS = 'innovA/auctions#getTermsAndConditions';
export const UPDATE_ITEM_MESSAGE = 'innovA/auctions#updateItemMessage';
export const UPDATE_USER_TERMS_ACCEPTANCE = 'innovA/auctions#updateUserTermsAcceptance';
