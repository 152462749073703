import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'reactstrap';

const ModalComponent = props => {
    const { toggle, isOpen, hasFooter = true, hasHeader = true, children, header, footer, maxWidth, className, size } = props;
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size={size}
            className={`${className} modal-dialog-centered`}
            style={{ maxWidth: maxWidth ? maxWidth : 'auto' }}
        >
            {hasHeader &&
                <ModalHeader toggle={toggle}>
                    {header}
                </ModalHeader>
            }
            <ModalBody>
                {children}
            </ModalBody>
            {hasFooter &&
                <ModalFooter>
                    {footer}
                </ModalFooter>
            }
        </Modal>
    )
}

export default ModalComponent;