import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';

const initialState = {
    errorMessage: null,
};

export default createReducer(initialState, {
    [actionTypes.SERVER_ERROR]: (state, action) => ({...state, ...action.payload.object}),
});
