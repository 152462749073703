import React from "react";
import { reduxForm } from "redux-form";

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";

const ConfirmationModal = (props) => {
  const {
    showModal,
    turnModal,
    text,
    terms,
    handleCancelSubmit,
    onSubmit,
    submitting,
    header,
  } = props;
  
  const toggle = () => {
    if (!submitting) {
      turnModal();
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={showModal}
      toggle={toggle}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>{header ? header : "Confirm"}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={2} sm={2} md={2}>
            <i
              style={{ fontSize: 40, color: "red" }}
              className="fa fa-exclamation-circle"
            />
          </Col>
          <Col xs={10} sm={10} md={10}>
            {text}
          </Col>
          <div className="m-3 d-flex justify-content-center sun-editor-editable p-2 rounded w-100"  style={{ height: "50vh" }}>
            <div
              dangerouslySetInnerHTML={{ __html: terms }}
              style={{width: "100%", overflowY: "auto" }}
            ></div>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter key="modal_footer">
        <div className="flex-wrapper">
          <Button
            type="button"
            className="btn btn-default"
            onClick={handleCancelSubmit}
          >
            Cancel
          </Button>
          <Button type="submit" className="btn btn-blue" onClick={onSubmit}>
            Accept
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const confirmationModalForm = reduxForm({
  form: "confirmationModal",
  enableReinitialize: true,
})(ConfirmationModal);

export default confirmationModalForm;
