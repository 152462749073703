import thunk from 'redux-thunk';
import {routerMiddleware} from 'react-router-redux';

import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import api from '../api';
import reducer from '../reducers';

// export const APP_CLEAR_STORE = 'blackbird_api/store#clear';

const authorization = JSON.parse(
  localStorage.getItem('auctionsAuthorization') || '{}',
);

const initialState = {authorization};
const extraArgument = {api};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('../api', () => {
    extraArgument.api = api;
  });
}

const middleware = history => applyMiddleware(
  thunk.withExtraArgument(extraArgument),
  routerMiddleware(history),
);

const composeEnhancers = process.env.NODE_ENV === 'production' ?
  compose :
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
  // if (action.type === APP_CLEAR_STORE) {
  //   state = {router: state.router};
  // }

  return reducer(state, action);
};

const storeCreator = history => createStore(
  rootReducer(history),
  initialState,
  composeEnhancers(middleware(history)),
);

export default storeCreator;
