import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';

const initialState = {
    loadedSuggestions: false,
    suggestions: [],
}

export default createReducer(initialState, {
    [actionTypes.GET_SUGGESTIONS]: (state, action) => ({...state, suggestions: action.payload.suggestions, loadedSuggestions: true}),
    [actionTypes.LOADING_SUGGESTIONS]: state => ({...state, loadedSuggestions: false}),
    [actionTypes.CLEAR_SUGGESTIONS]: state => ({...state, suggestions: [], loadedSuggestions: true}),
    [actionTypes.CHECK_ZIP]: (state, action) => ({...state, ...action.payload}),
    [actionTypes.VALIDATE_ADDRESS]: (state, action) => ({...state, ...action.payload}),
  });