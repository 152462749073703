import {
    setMakes,
    setBoolean,
    setCategories,
    setFamilies,
    setFilteredItem,
    setYears,
    setLocations,
    setModels
} from '../reducers/search';
import persistSearch from './persistSearch';

export const checkForStringValue = value => (dispatch, getState) => {
    const { search: { makes = [], categories = [], years = [], locations = [], models = [], filteredItems } } = getState();

    const filterArray = [...makes, ...years, ...locations, ...models];

    for (let i = 0; i < categories.length; i++) {
        filterArray.push({
            id: categories[i].id,
            value: categories[i].value,
            isChecked: categories[i].isChecked,
        })
        const families = categories[i].families;
        for (let x = 0; x < families.length; x++) {
            filterArray.push({
                category_ID: families[x].category_ID,
                family_ID: families[x].family_ID,
                isChecked: families[x].isChecked,
                value: families[x].value,
            })
        }
    }

    let promise = new Promise((resolve) => {
        resolve(filterArray.forEach(data => {
            if (data.value.toUpperCase() === value.toUpperCase()) {
                if (Object.keys(data).includes('make_ID')) {
                    dispatch(setMakes(data.make_ID));
                    dispatch(setFilteredItem(value));
                    dispatch(setBoolean({
                        make_ID: data.make_ID,
                        isChecked: true,
                    }));
                    const defaultBehavior = document.querySelector(`#makes`);
                    if (defaultBehavior) {
                        defaultBehavior.click();
                    }
                }
                if (Object.keys(data).includes('id')) {
                    dispatch(setCategories(data.id));
                    dispatch(setBoolean({
                        id: data.id,
                        isChecked: true,
                    }));
                }
                if (Object.keys(data).includes('family_ID')) {
                    dispatch(setFamilies(data.family_ID));
                    dispatch(setBoolean({ family_ID: data.family_ID, isChecked: true, bool: true, string: data.value, category_ID: data.category_ID }));
                    dispatch(setCategories(data.category_ID));
                    dispatch(setBoolean({ id: data.category_ID, isChecked: true }));

                    const getCategoryString = categories.filter(item => item.id === data.category_ID);

                    dispatch(persistSearch({ categories: getCategoryString[0].value }, 'categories'));
                }
                if (Object.keys(data).includes('year_ID')) {
                    dispatch(setYears(data.year_ID));
                    dispatch(setBoolean({ year_ID: data.year_ID, isChecked: true }));

                    const defaultBehavior = document.querySelector(`#years`);
                    if (defaultBehavior) {
                        defaultBehavior.click();
                    }
                }
                if (Object.keys(data).includes('location_ID')) {
                    dispatch(setLocations(data.value));
                    dispatch(setBoolean({ location_ID: data.location_ID, isChecked: true }));
                }
                if (Object.keys(data).includes('model_ID')) {
                    dispatch(setModels(data.model_ID));
                    dispatch(setBoolean({ model_ID: data.model_ID, isChecked: true }));
                }
            }
        }))
    })
    promise.then(() => {
        if (makes.some(el => el.value === value.toUpperCase())) {
            return false
        } else if (categories.some(item => item.value.toUpperCase() === value.toUpperCase())) {
            return false
        } else if (categories.some(item => item.families.some(item => item.value.toUpperCase() === value.toUpperCase()))) {
            return false
        } else if (years.some(item => item.value === value)) {
            return false
        } else if (locations.some(item => item.value.toUpperCase() === value.toUpperCase())) {
            return false;
        } else if (locations.some(item => item.value.toUpperCase() === value.toUpperCase())) {
            return false;
        } else if (models.some(item => item.value.toUpperCase() === value.toUpperCase())) {
            return false;
        } else if (!filteredItems.some(item => item.toUpperCase() === value.toUpperCase())) {
            dispatch(setFilteredItem(value));
        }
    })
}