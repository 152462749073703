import storage from 'redux-persist/lib/storage';

export const mainPersistConfig = {
    key: 'main',
    storage,
    whitelist: ['categories', 'featured', 'upcomingEvents', 'featuredCategories', 'hasConsented'],
  };

  export const registerPersistConfig = {
    key: 'register',
    storage,
    whitelist: ['countries'],
  };

  export const eventsPersistConfig = {
    key: 'events',
    storage,
    whitelist: ['sortedEvents'],
  };

  export const auctionPersistConfig = {
    key: 'auction',
    storage,
    whitelist: ['gallery', 'preBid'],
  };

  export const comparePersistConfig = {
    key: 'compare',
    storage,
    whitelist: ['comparedItems'],
  };

  export const liveAuctionPersistConfig = {
    key: 'liveAuction',
    storage,
    whitelist: ['instaBid']
  }

  export const searchPersistConfig = {
    key: 'search',
    storage,
    whitelist: ['models', 'itemNavigator']
  }

