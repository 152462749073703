import { createReducer } from '../../store/utils';
// import * as actionTypes from './action-types';

const initialState = {
    notifications: [
        {
            id: 1,
            message: 'This is a notifications',
            date: '2012-04-23T18:25:43.511Z',
        },
        {
            id: 2,
            message: 'This is a notifications',
            date: '2012-04-14T18:25:43.511Z',
        },
        {
            id: 3,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 4,
            message: 'This is a notifications',
            date: '2012-04-23T18:25:43.511Z',
        },
        {
            id: 5,
            message: 'This is a notifications',
            date: '2012-04-26T18:25:43.511Z',
        },
        {
            id: 6,
            message: 'This is a notifications',
            date: '2012-04-27T18:25:43.511Z',
        },
        {
            id: 7,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 8,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 9,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 10,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 11,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 12,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
        {
            id: 13,
            message: 'This is a notifications',
            date: '2012-04-11T18:25:43.511Z',
        },
    ],
    preferences: {
        email: true,
        mobile: true,
        outBid: true,
        rejected: true,
        accepted: true,
        counter: true,
        starting: true,
        ended: true,
        canceled: true,
    }
}

export default createReducer(initialState, {

});