import { push } from 'react-router-redux';
import { getAuctionItem, getPhotoGallery } from '../reducers/auction';

export default data => async (dispatch, getState) => {
  const { router: { location: { pathname } }, authorization: { latitude, longitude } } = getState();
  if (pathname.includes('item')) {
    dispatch(getAuctionItem({
      id: data.item_ID,
      lat: latitude,
      lon: longitude
    }));
    dispatch(getPhotoGallery({
      item_ID: data.item_ID,
    }))
    if (data.seo_Description) {
      dispatch(push(`/item/${formatValue(data.category)}/${formatValue(data.family)}/${data.seo_Description}/${data.item_ID}`));
    } else {
      dispatch(push(`/item/${formatValue(data.category)}/${formatValue(data.family)}/${formatValue(data.year)}/${formatValue(data.make)}/${formatValue(data.model)}/${data.item_ID}`));
    }
  } else if (data.descriptionOnly) {
    dispatch(push(`/item/${formatValue(data.category)}/${formatValue(data.family)}/${data.seo_Description}/${data.item_ID}`));
  } else {
    dispatch(push(`/item/${formatValue(data.category)}/${formatValue(data.family)}/${formatValue(data.year)}/${formatValue(data.make)}/${formatValue(data.model)}/${data.item_ID}`));
  }
};

const formatValue = val => {
  if (val === "") {
    return "na";
  }
  else {
    return encodeURIComponent(val);
  }
}