import { get, create } from './common';

export default {
    // getFeaturedItems: (params, token) => get(`/v1/Auction/GetFeaturedItems`, token),
    getUpcomingAuctionsPreview: async (params, token) => get(`/v1/Auction/GetUpcomingAuctionsPreview`, token),
    getCategoriesWithFamilies: async (params, token) => get(`/v1/Auction/GetCategoriesWithFamilies`, token),
    getFeaturedCategories: async (params, token) => get(`/v1/Auction/GetFeaturedCategories`, token),
    getPreviouslyViewedItems: async (params, token) => get(`/v1/Auction/GetPreviouslyViewedItems`, token),
    getAuctionBlock: async (params, token) => get(`/V1/auction/GetAuctionBlock`, token),
    subscribeToNewsletter: async (params, token) => get(`/V1/settings/SubscribeEmailToNewsletter?emailAddress=${params.emailAddress}`, token),
    getContentDocuments: async (params, token) => get(`/V1/auction/GetContentDocuments`, token),
    createContactUsLead: async (params, token) => create(`/V1/lead/CreateLeadFromAuctionSite`, params, token),
}