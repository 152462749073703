export default (value) => (dispatch, getState) => {
    const { search: { years, makes, models, categories, locations } } = getState();

    if (categories.some(category => category.value.toUpperCase() === value.toUpperCase())) {
        return 'categories';
    } else if (categories.some(category => category.families.some(fam => fam.value.toUpperCase() === value.toUpperCase()))) {
        return 'families';
    } else if (years.some(year => year.value === value)) {
        return 'years';
    } else if (makes.some(make => make.value.toUpperCase() === value.toUpperCase())) {
        return 'makes';
    } else if (models.some(model => model.value.toUpperCase() === value.toUpperCase())) {
        return 'models';
    } else if (locations.some(location => location.value.toUpperCase() === value.toUpperCase())) {
        return 'locations';
    } else {
        return 'search_string';
    }

}