import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';
import {APP_CLEAR_STORE} from '../authorization/action-types';

const initialState = {
    loading_upload_document: false,
    documents: [],
    loading_documents: false,
    document: null,
};

export default createReducer(initialState, {
    [APP_CLEAR_STORE]: () => ({...initialState}),
    [actionTypes.UPDATE_DOCUMENT_STATE]: (state, action) => ({ ...state, ...action.payload.object }),
    [actionTypes.UPLOAD_DOCUMENT]: (state, action) => ({...state, documents: [...state.documents, action.payload], loading_upload_document: false}),
    [actionTypes.LOADING_UPLOAD_DOCUMENT]: state => ({...state, loading_upload_document: true}),
    [actionTypes.GET_DOCUMENTS]: (state, action) => ({...state, ...action.payload, loading_documents: false}),
    [actionTypes.LOADING_DOCUMENTS]: (state) => ({...state, loading_documents: true}),
    [actionTypes.REQUEST_DOCUMENT_DOWNLOAD]: (state, action) => ({...state, loading_documents: true, requestedDocument: action.payload.id}),
    [actionTypes.DOCUMENT_DOWNLOAD]: (state, action) => ({...state, loading_documents: false, document: action.file}),
    [actionTypes.REQUEST_USER_DOCUMENT_DOWNLOAD]: (state, action) => ({...state, loading_documents: true, requestedDocument: action.payload.document_ID}),
    [actionTypes.DELETE_DOCUMENT]: (state, action) => ({...state, documents: state.documents.filter(d => d.id !== action.payload.id)}),
    [actionTypes.UPDATE_SPINNER_STATE]: (state, action) => {
        const temp = [...state.documents];
        temp.forEach(item => {
            // eslint-disable-next-line eqeqeq
            if (item.id == action.payload.object) {
                if (item.spinner === undefined) {
                    item.spinner = true;
                } else {
                    item.spinner = !item.spinner;
                }
            }
        })
        return {
            ...state, documents: temp,
        }
    }
});
