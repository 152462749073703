export const GET_USER_INFO = 'innovA/account#getUserInfo';
export const LOADING_USER = 'innovA/account#loadingUser';
export const LOADING_USER_OFFERS = 'innovA/account#loadingUserOffers';
export const GET_USER_OFFER_HISTORY = 'innovA/account#getUserOfferHistory';
export const UPDATE_ACCOUNT_STATE = 'innovA/account#updateAccountState';
export const UPDATE_USER_ACCOUNT = 'innovA/account#updateUserAccount';
export const SET_USER_PERMISSIONS = 'innovA/account#setExternalUserPermissions';
export const GET_USER_PERMISSIONS = 'innovA/account#getExternalUserPermissions';
export const GET_LISTED_ITEMS = 'innovA/account#getListedItems';
export const LOADING_ITEMS = 'innovA/account#loadingItems';
export const GET_SOLD_ITEMS = 'innovA/account#getSoldItems';
export const GET_WONPURCHASED_ITEMS = 'innovA/account#getWonPurchasedItems';
export const GET_LOST_ITEMS = 'innovA/account#getLostItems';
export const LOADING_UPDATE_ACCOUNT = 'innovA/account#loadingUpdateAccount';
//TODO: PAYMENT METHODS 
// export const ADD_USER_PAYMENT_METHOD = 'innovA/account#addUserPaymentMethod';
export const EDIT_USER_PAYMENT_METHOD = 'innovA/account#editUserPaymentMethod';
export const GET_USER_PAYMENT_METHODS = 'innovA/account#getUserPaymentMethod';
export const DELETE_USER_PAYMENT_METHOD = 'innovA/account#deletUserPaymentMethod';
export const CREATE_USER_PAYMENT_PROFILE = 'innovA/account#createUserPaymentProfile';
export const CHARGE_USER_PAYMENT_PROFILE = 'innovA/account#chargeUserPaymentProfile';
export const PAY_INVOICE = 'innovA/payment#payInvoice';
export const LOADING_PAY_INVOICE = 'innovA/payment#loadingPayInvoice';
export const GET_PAYMENT_METHODS = 'innovA/payment#getPaymentMethods';
export const LOADING_ADD_USER_PAYMENT = 'innovA/payment#loadingAddUserPayment';
export const ADD_CUSTOMER_PAYMENT_METHOD = 'innovA/payment#addCustomerPaymentMethod';
export const LOADING_CUSTOMER_ADD_PAYMENT = 'innovA/payment#loadingCustomerAddPayment';
export const LOADING_PAYMENT_METHODS = 'innovA/payment#loadingPaymentMethods';

export const GET_INVOICES = 'innovA/account#getInvoices';
export const LOADING_INVOICES = 'innovA/account#loadingInvoicse';
export const GET_INVOICE_HTML = 'innovA/account#getInvoiceHTML';
export const LOADING_GET_INVOICE_HTML = 'innovA-acutions/account#loadingGetInvoiceHtml';
export const GET_INVOICE_PDF = 'innovA/account#getInvoicePDF';
export const GET_RELEASE_TICKET_HTML = 'innovA/account#getReleaseTicketHTML';
export const LOADING_GET_RELEASE_TICKET_HTML = 'innovA-acutions/account#loadingGetReleaseTicketHtml';
export const GET_RELEASE_TICKET_PDF = 'innovA/account#getReleaseTicketPDF';
export const LOADING_RELEASE_DOWNLOAD_PDF = 'innovA/accont#loadingReleaseDownloadPDF';
export const LOADING_DOWNLOAD_PDF = 'innovA/accont#loadingDownloadPDF';
export const LOADING_PAY_INVOICE_CC = 'innovA/payment#loadingPayInvoice';
export const PAY_INVOICE_CC = 'innovA/payment#payInvoivecc';
export const CREDIT_CARD_DEPOSIT = 'innovA/payment#creditCardDeposit';
export const SAVED_METHOD_DEPOSIT = 'innovA/payment#savedMethodDeposit';
export const LOADING_DEPOSIT_METHOD = 'innovA/payment#loadingDepositMethod';
export const GET_SURCHARGE = 'innovA/payment#getSurcharge';
export const CLEAR_SURCHARGE = 'innovA/payment#clearSurcharge';
export const UPDATE_PHYSICAL_ADDRESS = 'innovA-auction/account#updatePhysicalAddress';
export const LOADING_UPDATE_ADDRESS = 'innovA/account#updateLoadingAddress';
export const UPDATE_BILLING_ADDRESS = 'innovA/account#updateBillingAddress';
export const GET_HANDOFF_TOKEN = 'innovA/settings#getHandOffToken';
export const GET_REGISTERED_AUCTIONS = 'innovA/account#getRegisteredAuctions';
export const GET_TAX_EXEMPTION_TYPES = 'innovA/account#getTaxExemptionTypes';
export const ADD_TAX_EXEMPTION = 'innovA/account#addTaxExemption';
export const GET_CONTACT_INFO = 'innovA/account#getContactInfo';
export const UPDATE_PHYSICAL_ADDRESS_V2 = 'innovA-auction/account#updatePhysicalAddressV2';
export const UPDATE_BILLING_ADDRESS_V2 = 'innovA/account#updateBillingAddressV2';
export const UPDATE_ACCOUNT_ADDRESS = 'innovA/account#updateAccountAddress';
export const UPDATE_TERMS_ACCEPTANCE = 'innovA/account#updateTermsAcceptance';
export const UPDATE_CONTACT_BIDDERTAG = 'innovA/account#updateContactBidderTag'