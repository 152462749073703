import { createAuthRemoteCall } from '../../store/utils';
import { logout } from '../authorization';
import * as actionTypes from './action-types';

export const getInventory = params => createAuthRemoteCall(
    { fetch: actionTypes.GET_INVENTORY, loading: actionTypes.LOADING }, api => api.search.getInventory, logout)(params);

export const getInventorySearchCriteria = params => createAuthRemoteCall(
    { fetch: actionTypes.GET_SEARCH_CRITERIA }, api => api.search.getInventorySearchCriteria, logout)(params);

export const getModelsForMakes = params => createAuthRemoteCall(
    { fetch: actionTypes.GET_MAKES_FOR_MODELS }, api => api.search.getModelsForMakes, logout)(params);


export const setFilteredItem = filter => ({
    type: actionTypes.SET_FILTERED_ITEMS,
    filter,
})

export const setFilteredDates = filter => ({
    type: actionTypes.SET_FILTERED_DATES,
    filter,
})

export const setFilteredIDs = filter => ({
		type: actionTypes.SET_FILTERED_IDS,
		filter,
})

export const removeFilteredItem = filter => ({
    type: actionTypes.REMOVE_FILTERED_ITEM,
    filter,
})

export const setYears = item => ({
    type: actionTypes.SET_YEARS,
    item,
});

export const setModels = item => ({
    type: actionTypes.SET_MODELS,
    item,
});

export const setSortValue = item => ({
    type: actionTypes.SET_SORT_VALUE,
    item,
});

export const setMakes = (item) => {
    return {
        type: actionTypes.SET_MAKES,
        item,
    }
};

export const setCategories = item => ({
    type: actionTypes.SET_CATEGORIES,
    item,
});

export const setLocations = item => ({
    type: actionTypes.SET_LOCATIONS,
    item,
});

export const setFamilies = item => ({
    type: actionTypes.SET_FAMILIES,
    item,
});

export const setStatusFilter = item => ({
    type: actionTypes.SET_STATUSFILTER,
    item,
});

export const clearFilters = (filteredItems, callback) => {
    if (callback) {
        callback();
    } else {
        return {
            type: actionTypes.CLEAR_FILTERS,
            payload: filteredItems
        }
    }
};

export const clearAllFilters = () => ({
  type: actionTypes.CLEAR_FILTERS,
})

export const clearModelsFilter = item => ({
    type: actionTypes.CLEAR_MODELS_FILTER_CHECK,
    payload: item,
})

export const setBoolean = item => ({
    type: actionTypes.SET_BOOLEAN,
    payload: item,
})

export const removeBoolean = (item) => {
    return {
        type: actionTypes.REMOVE_BOOLEAN,
        payload: item,
    }
}

export const setPagination = item => ({
    type: actionTypes.SET_PAGINATION,
    payload: { object: item }
})

export const resetLoader = () => ({
    type: actionTypes.RESET_LOADER,
    payload: {}  
})

export const clearSearch = () => ({
  type: actionTypes.CLEAR_SEARCH,
  payload: {}
})

export const setItemNavigator = (items) => ({
  type: actionTypes.SET_ITEM_NAVIGATOR,
  payload: items
})