import { setCategories, setBoolean, setFamilies, setYears, setMakes, setLocations, setFilteredDates, setFilteredIDs, setFilteredItem, setPagination, setStatusFilter } from '../reducers/search';
import {simpleDataFormatShort} from '../utils/common';

export const parseQueryString = paramsString => JSON.parse(paramsString ?
    // eslint-disable-next-line no-div-regex
    '{"' + paramsString.replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}' : '{}',
);

export const replaceCharString = value => value.replace(/and/g, "&").replace(/%20/g, " ").replace(/"/g, "'").replace(/%27/g, " ");

export const getQueryParamsSearch = (obj) => async (dispatch, getState) => {
    const { search: { categories = [], years = [], makes = [], locations = [], events = [] } } = getState();
    const decode = decodeURI(obj);
    const searchParams = parseQueryString(decode);

    const categoriesToArr = searchParams.categories && replaceCharString(searchParams.categories).split("|");
    const familiesToArr = searchParams.families && replaceCharString(searchParams.families).split("|");
    const yearsToArr = searchParams.years && replaceCharString(searchParams.years).split("|");
    const makesToArr = searchParams.makes && replaceCharString(searchParams.makes).split("|");
    const locationsToArr = searchParams.locations && replaceCharString(searchParams.locations).split("|");
    const searchStringArr = searchParams.search_string && replaceCharString(searchParams.search_string).split("|");
    // const datesStringArr = searchParams.events && replaceCharString(searchParams.events).split("|");
		const idsStringArr = searchParams.events && replaceCharString(searchParams.events).split("|");

    if (categoriesToArr !== undefined) {
        categories.forEach(cat => {
            categoriesToArr && categoriesToArr.forEach(item => {
                if (cat.value.toUpperCase() === item.toUpperCase()) {
                    dispatch(setCategories(cat.id));
                    dispatch(setBoolean({ id: cat.id, isChecked: true }));
                }

                if (cat.families && familiesToArr !== undefined) {
                    cat.families.forEach(fam => {
                        familiesToArr.forEach(obj => {
                            if (fam.value.toUpperCase() === obj.toUpperCase()) {
                                dispatch(setFamilies(fam.family_ID));
                                dispatch(setBoolean({ family_ID: fam.family_ID, isChecked: true, bool: true, string: fam.value, category_ID: fam.category_ID }));
                            }
                        })
                    })
                }
            })
        })
    } 

    if (yearsToArr !== undefined) {
        years.forEach(year => {
            yearsToArr.forEach(value => {
                if (year.value === value) {
                    dispatch(setYears(year.value));
                    dispatch(setBoolean({ year_ID: year.year_ID, isChecked: true }));
                }
            })
        })
    }
    if (makesToArr !== undefined) {
        makes.forEach(make => {
            makesToArr.forEach(item => {
                if (make.value.toUpperCase().replace(/"/g, " ") === item.toUpperCase()) {
                    dispatch(setMakes(make.make_ID))
                    dispatch(setBoolean({ make_ID: make.make_ID, isChecked: true }))
                }
            })
        })
    }

    if (locationsToArr !== undefined) {
        locations.forEach(loc => {
            locationsToArr.forEach(item => {
                if (loc.value.toUpperCase() === item.toUpperCase()) {
                    dispatch(setLocations(loc.value))
                    dispatch(setBoolean({ location_ID: loc.location_ID, isChecked: true }));
                }
            })
        })
    }

    if (searchStringArr !== undefined) {
        searchStringArr.forEach(item => {
            dispatch(setFilteredItem(item));
        })
    }

    // if (datesStringArr !== undefined) {
    //     events.forEach(event => {
    //         datesStringArr.forEach(item => {
    //             if (item === simpleDataFormatShort(event.value)) {
    //                 dispatch(setFilteredDates(simpleDataFormatShort(event.value)));
    //                 dispatch(setBoolean({ event_ID: event.event_ID, isChecked: true }));
    //             }
    //         })
    //     })
    // }

		if (idsStringArr !== undefined) {
				events.forEach(event => {
						idsStringArr.forEach(item => {
								if (item === event.event_ID) {
										dispatch(setFilteredIDs(event.event_ID));
										dispatch(setBoolean({ event_ID: event.event_ID, isChecked: true }));
								}
						})
				})
		}

    if (searchParams.page_number !== undefined) {
        dispatch(setPagination({pageNumber: parseFloat(searchParams.page_number)}));
    }

    if (searchParams.status !== undefined) {
        dispatch(setStatusFilter(searchParams.status));
    }
}