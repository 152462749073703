import { createReducer, normalizeArray } from '../../store/utils';
import * as actionTypes from './action-types';
import {APP_CLEAR_STORE} from '../authorization/action-types';

const initialState = {
    featured: [],
    upcomming: [],
    categories: [],
    featuredCategories: [],
    recentlyViewed: [],
    upcomingAuctionBlock: [],
    isNavEvent: false,
    hasConsented: false,
    contentDocuments: [],
}

export default createReducer(initialState, {
    [APP_CLEAR_STORE]: () => ({...initialState}),
    [actionTypes.LOADING_UPCOMING_AUCTIONS]: state => ({...state, loaded_upcoming: false}),
    // [actionTypes.GET_FEATURED_ITEMS]: (state, action) => ({...state, featured: action.payload}),
    [actionTypes.GET_RECENT_ITEMS]: (state, action) => ({...state, recentlyViewed: action.payload}),
    [actionTypes.GET_UPCOMMING_AUCTIONS]: (state, action) => ({...state, upcomingEvents: action.payload, loaded_upcoming: true}),
    [actionTypes.GET_CATEGORIES_WITH_FAMLILIES]: (state, action) => {
        const cat = normalizeArray(action.payload.filter(item => item.value !== 'Unknown'));
        return {
            ...state, categories: cat,
        }
    },
    [actionTypes.GET_FEATURED_CATEGORIES]: (state, action) => {
        const payload = action.payload;
        payload.unshift({
            id: 0,
            value: 'ALL'
        })
        return {
            ...state, featuredCategories: payload,
        }
    },
    [actionTypes.GET_AUCTION_BLOCK]: (state, action) => ({...state, upcomingAuctionBlock: action.payload}),
    [actionTypes.GET_CONTENT_DOCUMENTS]: (state, action) => ({...state, contentDocuments: action.payload}),
    [actionTypes.SUBSCRIBE_NEWSLETTER]: (state, action) => ({...state, ...action.payload}),
    [actionTypes.SET_IS_NAV_EVENT]: (state, action) => ({...state, ...action.payload.object}),
    [actionTypes.UPDATE_MAIN_STATE]: (state, action) => ({...state, ...action.payload.object}),
    [actionTypes.CREATE_CONTACT_US_LEAD]: (state, action) => ({...state, ...action.payload}),
});