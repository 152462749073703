import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';
import { APP_CLEAR_STORE } from '../authorization/action-types';

const initialState = {
    watchListItems: [],
    loaded: false,
}

export default createReducer(initialState, {
    [APP_CLEAR_STORE]: () => ({...initialState}),
    [actionTypes.LOADING_WATCHLIST]: (state) => ({...state, loaded: false}),
    [actionTypes.GET_WATCHLIST]: (state, action) => ({...state, watchListItems: action.payload.watchList, loaded: true}),
    [actionTypes.ADD_TO_WATCH_LIST]: (state, action) => ({...state, watchListItems: [...state.watchListItems, ...action.payload]}),
    [actionTypes.REMOVE_FROM_WATCH_LIST]: (state, action) => ({...state, watchListItems: state.watchListItems.filter((item) => item.item_ID !== action.payload[0].item_ID)}),
});