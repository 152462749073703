import moment from 'moment';

export const formatDate = date => {
  return moment.utc(date).local().format('MM/DD/YYYY');
}

export const simpleDateFormat = date => {
  return moment.utc(date).local().format('dddd, MMMM DD YYYY');
}

export const complexDateFormat = date => {
    return moment.utc(date).local().format('dddd, MMMM DD YYYY hh:mm A');
}

export const simpleDataFormatShort = date => {
  return moment.utc(date).local().format('ddd, MMM DD, YYYY');
}

export const localTimeFormat = date => {
  return moment.utc(date).local().format('LT');
}

export const localTimeAndTimeZoneFormat = date => {
  return moment.utc(date).local().format('LT') + " " + moment.tz(moment.tz.guess()).format('z')
}

export const convertToDollars = value => {
  if (value) {
    if (value === 0) {
      return '$0.00';
    }
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  return '$0.00';
};

export const handleFocus = e => {
  e.target.select();
};

export const formatPhoneNumber = value => {
  const string = value.toString();
  return string.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const onlyNums = value => value && value.replace(/[^\d]/g, '');

export const onlyNumsWithDecimal = value => value && value.replace(/[^0-9.]/g, '');

export const normalizeBool = value => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

