import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import {persistReducer} from 'redux-persist';
import {
    mainPersistConfig,
    eventsPersistConfig,
    auctionPersistConfig,
    comparePersistConfig,
    liveAuctionPersistConfig,
    searchPersistConfig,
    registerPersistConfig,
} from '../store/persist-config';

import authorization from './authorization/reducer';
import events from './events/reducer';
import search from './search/reducer';
import watchList from './watchlist/reducer';
import cart from './cart/reducer';
import liveAuction from './live-auction/reducer';
import register from './register/reducer';
import auction from './auction/reducer'
import streets from './streets/reducer';
import notifications from './notifications/reducer';
import main from './main/reducer';
import userAccount from './account/reducer';
import compare from './compare/reducer';
import error from './error/reducer';
import documents from './documents/reducer';
import settings from './settings/reducer';

const appReducer = history => combineReducers({
    form: formReducer,
    router: connectRouter(history),
    authorization: authorization,
    events: persistReducer(eventsPersistConfig, events),
    search: persistReducer(searchPersistConfig, search),
    watchList: watchList,
    cart,
    liveAuction: persistReducer(liveAuctionPersistConfig, liveAuction),
    register: persistReducer(registerPersistConfig, register),
    auction:  persistReducer(auctionPersistConfig, auction),
    streets,
    notifications,
    compare: persistReducer(comparePersistConfig, compare),
    main: persistReducer(mainPersistConfig, main),
    userAccount: userAccount,
    error,
    documents,
    settings,
});

const initialState = appReducer({}, {});
const rootReducer = (state = initialState, action = {}) => {
  return appReducer(state, action);
};

export default rootReducer;
