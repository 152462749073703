export const EventTypes = {
    Online: 1,
    Simulcast: 2,
    ThirdParty: 3
};

export const EventTypeStrings = {
    1: 'Online',
    2: 'Simulcast',
    3: 'Third Party'
};