import React from "react";
import { Button } from "reactstrap";


const ProviderRegistration = ({ providers }) => {
    const providersCount = providers.filter(provider => provider.isCatalogURLLive || provider.isRegisterURLLive).length
    return (
        <div className={`w-100 ${providersCount === 2 ? "d-flex" : ""}`}>
            {providers.filter(provider => provider.isCatalogURLLive || provider.isRegisterURLLive).map((provider) => (
                <div key={provider.id} className={`mt-1 mx-1 ${providersCount === 2 ? " w-50" : " w-100"}`}>
                    <div className="mb-3 btn btn-secondary w-100" style={{ textAlign: "center", background: "#555", color: "#FFF" }}>
                        {provider.name}
                    </div>
                    {provider.isRegisterURLLive && (
                        <Button
                            className="alert-secondary w-100"
                            onClick={() => window.open(provider.providerRegisterURL, "_blank")}
                        >
                            <i className="fa fa-calendar fa-lg boldLinkButton">
                                <span
                                    style={{
                                        fontFamily: "MyriadReg",
                                        fontSize: "14px",
                                        marginLeft: "15px",
                                        textTransform: "uppercase",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    register
                                </span>
                            </i>
                        </Button>
                    )}
                    {provider.isCatalogURLLive && (
                        <Button onClick={() => window.open(provider.catalogURL, "_blank")} className={`${provider.isRegisterURLLive ? "mt-1" : ""} btn btn-secondary btn-block w-100`}>
                            <span>View Catalog</span>
                        </Button>
                    )}
                </div>
            ))}
        </div>
    )
}

export default ProviderRegistration;
