import React, { lazy, useEffect, useState } from "react";
import "../../common/index.scss";
import {
  setFilteredItem,
  setFilteredDates,
  setFilteredIDs,
  clearFilters,
  getInventorySearchCriteria,
} from "../../../reducers/search";
import { checkForStringValue } from "../../../utils/check-filter-for-value";
import {
  getUpcomingAuctionsPreview,
  getRecentItems,
  getAuctionBlock,
} from "../../../reducers/main";
import { getWatchList } from "../../../reducers/watchlist";
import {
  getUpcomingEventsSorted, getTodaysEvents, loadingTodaysEvents, registerForAuctions,
  validateTermsAcceptanceByEventId,
} from "../../../reducers/events";
import { getUserInformation } from "../../../reducers/account";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { checkForLiveAuction } from "../../../utils/check-for-live-auction";
import { Container } from "reactstrap";
import RecentlyViewed from "./recently-viewed";
import checkForCriteriaKeys from "../../../utils/check-for-criteria-keys";
import handlePathChoice from "../../../utils/handle-path-choice";
import LiveAuctionBanner from "./live-auction-banner";
import { updateRegisterState } from "../../../reducers/register";
import TodaysEvents from "./todays-events.js"
import { Row, Col, Button } from 'reactstrap'
import ConfirmationModal from '../../common/modal/confirmation-modal'
import RegisterForm from "../../account/login";
import RegisterCompleteModal from '../../common/modal'
import { updateUserTermsAcceptance } from "../../../reducers/auction";
import { updateTermsAcceptance } from "../../../reducers/account";

const Tabs = lazy(() => import("../../common/tabs"));
const UpcommingAuctions = lazy(() => import("./upcoming-auctions"));
const Cta = lazy(() => import("./cta"));
const LoginRegister = lazy(() => import("../../account/login"));


const Home = (props) => {
  const {
    getRecentItems,
    getUpcomingAuctionsPreview,
    getWatchList,
    isSignedIn,
    upcoming,
    push,
    loaded_upcoming,
    recentlyViewed,
    getAuctionBlock,
    auctionBlock,
    setFilteredDates,
    setFilteredIDs,
    clearFilters,
    getInventorySearchCriteria,
    checkForCriteriaKeys,
    handlePathChoice,
    getUserInformation,
    getUpcomingEventsSorted,
    content,
    location,
    updateRegisterState,
    getTodaysEvents,
    todaysEventsLoaded,
    loadingTodaysEvents,
    todaysEvents,
    registerForAuctions,
    validateTermsAcceptanceByEventId,
    hasAccount,
    updateUserTermsAcceptance,
    updateTermsAcceptance,
    passwordRequest,
    isGuest
  } = props;

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [loginModalOpen, setLoginModal] = useState(false);
  const [termsAndConditionsDocument, setTermsAndConditionsDocument] = useState("");
  const [showTermsAcceptanceModal, toggleTermsAcceptanceModal] = useState(false);
  const [completeRegistrationModal, setCompleteRegistrationModal] = useState(
    false
  );
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getUpcomingAuctionsPreview(),
          getWatchList(),
          getRecentItems(),
          getUserInformation(),
          loadingTodaysEvents(),
          getTodaysEvents(),
          getAuctionBlock()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    let searchParams = {};
    const search = location.search.replace("?", "").split("&");

    search.forEach((x) => {
      if (x.includes("=")) {
        let arr = x.split("=");
        searchParams = {
          ...searchParams,
          [arr[0]]: arr[1],
        };
      }
    });
    if (searchParams.code) {
      setIsPopUpOpen(prevState => !prevState);
      updateRegisterState({
        passwordRequest: true,
      });
    }
  }, [location.search]);

  const handleRegisterForEvent = async (event, callback) => {
    if (isGuest) {
      setLoginModal(!loginModalOpen);
      return;
    }

    setSelectedEvent(event);
    const params = {
      notificationsType: {
        txtNotification: true,
        emailNotification: true,
      },
      register: !event.isRegistered,
      event_ID: event.event_ID,
      ...event,
    };

    if (!hasAccount) {
      setCompleteRegistrationModal(true);
      return;
    }

    if (event.isRegistered) {
      await registerForAuctions(params, callback);
      return;
    }

    const result = await validateTermsAcceptanceByEventId({
      event_ID: event.event_ID,
    });

    if (result.error) {
      return;
    }

    if (!result.isCurrentTermsAcceptance) {
      setTermsAndConditionsDocument(result.termsAndConditionsDocument);
      toggleTermsAcceptanceModal(true);
    } else {
      await registerForAuctions(params, callback);
    }
  };


  const handleTermsAcceptanceClick = async () => {
    await updateUserTermsAcceptance(
      { event_ID: selectedEvent.event_ID },
      async (result) => {
        toggleTermsAcceptanceModal(false);
        await registerForAuctions(
          {
            notificationsType: {
              txtNotification: true,
              emailNotification: true,
            },
            register: !selectedEvent.isRegistered,
            ...selectedEvent,
          },
          getTodaysEvents
        );
        updateTermsAcceptance(result);
      }
    );
    // if (!result.error) {
    //   toggleTermsAcceptanceModal(false);
    //   await registerForAuctions({
    //     notificationsType: {
    //       txtNotification: true,
    //       emailNotification: true,
    //     },
    //     register: !selectedEvent.isRegistered,
    //     ...selectedEvent,
    //   }, getTodaysEvents);
    // } else {
    //   toggleTermsAcceptanceModal(false);
    // }
  };


  const handleGoToMyAccount = () => {
    if (!hasAccount) {
      push("/register");
    } else {
      push("/myaccount");
    }
    setCompleteRegistrationModal(!completeRegistrationModal);
  };



  return (
    <React.Fragment>
      {showTermsAcceptanceModal && (
        <ConfirmationModal
          showModal={showTermsAcceptanceModal}
          turnModal={toggleTermsAcceptanceModal}
          onSubmit={handleTermsAcceptanceClick}
          handleCancelSubmit={() =>
            toggleTermsAcceptanceModal((prevState) => !prevState)
          }
          header={"Accept Event's Terms and Conditions"}
          text={`The Terms and Conditions have changed. Please read the new version and accept to continue or you will not be able to register for the event.`}
          terms={termsAndConditionsDocument}
        />
      )}
      {loginModalOpen && (
        <RegisterForm
          {...{
            passwordRequest,
          }}
          isNavEvent={true}
          toggle={() => setLoginModal(!loginModalOpen)}
          isOpen={loginModalOpen}
        />
      )}
      {completeRegistrationModal && (
        <RegisterCompleteModal
          header={"Complete Registration"}
          children={
            <h3>
              To register for events please complete your registration
            </h3>
          }
          footer={
            <Button
              style={{ width: "100%" }}
              onClick={() => handleGoToMyAccount()}
              className="btn btn-blue"
            >
              Go to my account
            </Button>
          }
          toggle={() =>
            setCompleteRegistrationModal(!completeRegistrationModal)
          }
          isOpen={completeRegistrationModal}
        />
      )}
      <section className="position-relative home-page-container">
        {content && content.theme.heroImage ? (
          <div
            className="header-container"
            style={{ backgroundImage: `url(${content.theme.heroImage})` }}
          ></div>
        ) : (
          <div className="header-container"></div>
        )}
        <Tabs
          {...{
            clearFilters,
            getInventorySearchCriteria,
            checkForCriteriaKeys,
            content,
          }}
        />
      </section>
      <section>
        <Container>
          <Row>
            {(todaysEventsLoaded && todaysEvents.length > 0) && (
              <TodaysEvents handleRegisterForEvent={handleRegisterForEvent} selectedEvent={selectedEvent} />
            )}
            {(!todaysEventsLoaded) && (
              <Col xs={12} className="py-5">
                <div className=" loader-spin-10" />
              </Col>
            )}
          </Row>
        </Container>
      </section>

      <section className="home">
        <Container>
          {/* {checkForLiveAuction() && < LiveAuctionBanner {...{ isSignedIn, auctionBlock, push }} />} */}
          {auctionBlock.items && auctionBlock.items.length >= 5 && (
            <LiveAuctionBanner
              {...{ isSignedIn, auctionBlock, push, handlePathChoice }}
            />
          )}
        </Container>
      </section>


      <section>
        <UpcommingAuctions
          {...{
            upcoming,
            push,
            loaded_upcoming,
            setFilteredDates,
            setFilteredIDs,
            clearFilters,
            handlePathChoice,
            handleRegisterForEvent
          }}
        />
      </section>

      <section>
        <Cta {...{ handlePathChoice, content }} />
      </section>

      {recentlyViewed.length > 1 ? (
        <div className="container">
          <section className="margin-top-25">
            <RecentlyViewed {...{ recentlyViewed, handlePathChoice }} />
          </section>
        </div>
      ) : (
        ""
      )}
      {isPopUpOpen && (
        <LoginRegister
          toggle={() => setIsPopUpOpen(!isPopUpOpen)}
          isOpen={isPopUpOpen}
        />
      )}
      <div className="flex-1"></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.authorization.status,
  categories: state.main.categories,
  recentlyViewed: state.main.recentlyViewed,
  upcoming: state.main.upcomingEvents,
  loaded_upcoming: state.main.loaded_upcoming,
  history: state.router,
  auctionBlock: state.main.upcomingAuctionBlock,
  content: state.authorization.content,
  location: state.router.location,
  todaysEvents: state.events.todaysEvents,
  isGuest: state.authorization.isGuest,
  todaysEventsLoaded: state.events.todaysEventsLoaded,
  hasAccount: state.userAccount.hasAccount,
  passwordRequest: state.register.passwordRequest
});

const mapDispatchToProps = {
  setFilteredItem,
  getRecentItems,
  getUpcomingAuctionsPreview,
  push,
  checkForStringValue,
  getWatchList,
  checkForLiveAuction,
  getAuctionBlock,
  setFilteredDates,
  setFilteredIDs,
  clearFilters,
  getInventorySearchCriteria,
  checkForCriteriaKeys,
  handlePathChoice,
  getUserInformation,
  getUpcomingEventsSorted,
  updateRegisterState,
  getTodaysEvents,
  loadingTodaysEvents,
  registerForAuctions,
  validateTermsAcceptanceByEventId,
  updateUserTermsAcceptance,
  updateTermsAcceptance
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
