import React from 'react';

import {
  Input,
  Label,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import { handleFocus, onlyNums } from '../../../utils/common';

const TextField = ({
  autoFocus,
  label,
  disabled,
  input,
  type,
  meta: { error },
  append,
  placeholder,
  enableErrorText = true,
  onInput,
  onlyNumbers = false,
  maxLength,
  hasEmptyLabel = true,
  style,
  disableComplete = false,
  noSpecialChar = false,
}) => {

  const handleChange = e => {
    try {
      if (onlyNumbers) {
        input.onChange(onlyNums(e.target.value))
      } else if (noSpecialChar) {
        input.onChange(e.target.value.replace(/[^\w\s]/gi, ''));
      }
      else if (e !== undefined && e.target !== undefined) {
        e.preventDefault();
        input.onChange(e.target.value)
      }
    }
    catch { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      {hasEmptyLabel ? label ? <Label htmlFor={input.name}>{label}</Label> : <Label htmlFor={input.name}>&nbsp;</Label> : null}
      <InputGroup>
        <Input
          type={type || 'text'}
          {...input}
          invalid={Boolean(error)}
          name={input.value}
          id={input.id}
          value={input.value}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={handleFocus}
          onInput={onInput}
          autoFocus={autoFocus}
          onChange={handleChange}
          maxLength={maxLength}
          style={style}
          autoComplete={disableComplete ? `new-password` : null}
        />
        {append &&
          <InputGroupAddon addonType="append">
            {append}
          </InputGroupAddon>
        }
      </InputGroup>
      {error && enableErrorText && <div className="error-text">{error}</div>}
    </React.Fragment>
  );
};

export default TextField;
