import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";
import removeDuplicates from "../../utils/check-for-dupes";
import { APP_CLEAR_STORE } from "../authorization/action-types";

const initialState = {
  filteredItems: [],
  loaded: false,
  items: [],
  yearsFilter: [],
  makesFilter: [],
  modelsFilter: [],
  categoriesFilter: [],
  locationsFilter: [],
  familiesFilter: [],
  sortValue: "",
  filteredDates: [],
  filteredIDs: [],
  statusFilter: 0,
  itemNavigator: [],
};

const flipBool = (
  array,
  arrayId,
  id,
  bool,
  isFam,
  remove,
  string,
  category_ID
) => {
  array &&
    array.forEach((data, idx) => {
      const familiesArray = array[idx].families;

      if (!isFam || isFam === undefined) {
        if (data[arrayId] === id) {
          data.isChecked = bool;

          if (familiesArray && remove) {
            familiesArray.forEach((item) => {
              if (
                item.family_ID === id ||
                item.category_ID === id ||
                item.value === string
              ) {
                item.isChecked = bool;
              }
            });
          }
        }
      } else {
        familiesArray &&
          familiesArray.forEach((item) => {
            if (item.family_ID === id && item.category_ID === category_ID) {
              item.isChecked = bool;
            }
          });
      }
    });
  return array;
};

const clearCheckBox = (array) => {
  array &&
    array.forEach((data) => {
      data.isChecked = false;
      if (data.families) {
        data.families.forEach((fam) => (fam.isChecked = false));
      }
    });
  return array;
};

export default createReducer(initialState, {
  [APP_CLEAR_STORE]: () => ({ ...initialState }),
  [actionTypes.LOADING]: (state) => ({ ...state, loaded: false }),
  [actionTypes.GET_INVENTORY]: (state, action) => {
    return {
      ...state,
      items: action.payload.items,
      loaded: true,
      pageCount: action.payload.pageCount,
      pageNumber: action.payload.pageNumber,
      totalRecordCount: action.payload.totalRecordCount,
      itemNavigator: action.payload.itemNavigator,
      isStockNumber: action.payload.isStockNumber
    };
  },
  [actionTypes.SET_FILTERED_ITEMS]: (state, action) => ({
    ...state,
    filteredItems: state.filteredItems.includes(action.filter.toUpperCase())
      ? state.filteredItems
      : state.filteredItems.concat(action.filter.toUpperCase()),
  }),
  [actionTypes.SET_FILTERED_DATES]: (state, action) => ({
    ...state,
    filteredDates: state.filteredDates.includes(action.filter)
      ? state.filteredDates
      : state.filteredDates.concat(action.filter),
  }),
  [actionTypes.SET_FILTERED_IDS]: (state, action) => ({
    ...state,
    filteredIDs: state.filteredIDs.includes(action.filter)
      ? state.filteredIDs
      : state.filteredIDs.concat(action.filter),
  }),
  [actionTypes.REMOVE_FILTERED_ITEM]: (state, action) => {
    const getIdValue = (array, id) => {
      const value = action.filter;
      const stateArray = state[array];
      for (let i = 0; i < stateArray.length; i++) {
        if (value === stateArray[i].value) {
          return stateArray[i][id];
        }
      }
    };

    const getFamID = (value) => {
      const cat = state.categories;
      for (let i = 0; i < cat.length; i++) {
        const families = cat[i].families;
        for (let x = 0; x < families.length; x++) {
          if (families[x].value === value) {
            return families[x].family_ID;
          }
        }
      }
    };
    return {
      ...state,
      filteredItems: state.filteredItems.filter(
        (item) => item !== action.filter
      ),
      yearsFilter: state.yearsFilter.filter(
        (year) => parseFloat(year) !== parseFloat(action.filter)
      ),
      makesFilter: state.makesFilter.filter(
        (make) => make !== getIdValue("makes", "make_ID")
      ),
      modelsFilter: state.modelsFilter.filter(
        (model) => model !== getIdValue("models", "model_ID")
      ),
      categoriesFilter: state.categoriesFilter.filter(
        (category) => category !== getIdValue("categories", "id")
      ),
      locationsFilter: state.locationsFilter.filter(
        (location) => location !== action.filter
      ),
      familiesFilter: state.familiesFilter.filter(
        (item) => item !== getFamID(action.filter)
      ),
      filteredDates: state.filteredDates.filter(
        (date) => date !== action.filter
      ),
      filteredIDs: state.filteredIDs.filter((id) => id !== action.filter),
    };
  },
  [actionTypes.CLEAR_FILTERS]: (state) => ({
    ...state,
    filteredItems: (state.filteredItems = []),
    yearsFilter: (state.yearsFilter = []),
    makesFilter: (state.makesFilter = []),
    modelsFilter: (state.modelsFilter = []),
    categoriesFilter: (state.categoriesFilter = []),
    locationsFilter: (state.locationsFilter = []),
    familiesFilter: (state.familiesFilter = []),
    makes: clearCheckBox(state.makes),
    years: clearCheckBox(state.years),
    models: clearCheckBox(state.models),
    categories: clearCheckBox(state.categories),
    locations: clearCheckBox(state.locations),
    events: clearCheckBox(state.events),
    pageCount: "",
    pageNumber: 1,
    statusFilter: 0,
    filteredDates: (state.filteredDates = []),
    filteredIDs: (state.filteredIDs = []),
  }),
  [actionTypes.SET_YEARS]: (state, action) => ({
    ...state,
    yearsFilter: removeDuplicates(state.yearsFilter.concat(action.item)),
  }),
  [actionTypes.SET_MAKES]: (state, action) => ({
    ...state,
    makesFilter: removeDuplicates(state.makesFilter.concat(action.item)),
  }),
  [actionTypes.SET_MODELS]: (state, action) => ({
    ...state,
    modelsFilter: [...new Set(state.modelsFilter.concat(action.item))],
  }),
  [actionTypes.SET_CATEGORIES]: (state, action) => ({
    ...state,
    categoriesFilter: [...new Set(state.categoriesFilter.concat(action.item))],
  }),
  [actionTypes.SET_LOCATIONS]: (state, action) => ({
    ...state,
    locationsFilter: removeDuplicates(
      state.locationsFilter.concat(action.item)
    ),
  }),
  [actionTypes.SET_FAMILIES]: (state, action) => ({
    ...state,
    familiesFilter: removeDuplicates(state.familiesFilter.concat(action.item)),
  }),
  [actionTypes.SET_PAGINATION]: (state, action) => ({
    ...state,
    ...action.payload.object,
  }),
  [actionTypes.SET_SORT_VALUE]: (state, action) => ({
    ...state,
    sortValue: action.item,
  }),
  [actionTypes.SET_STATUSFILTER]: (state, action) => ({
    ...state,
    statusFilter: action.item,
  }),
  [actionTypes.SET_BOOLEAN]: (state, action) => {
    return {
      ...state,
      years: flipBool(
        state.years,
        "year_ID",
        action.payload.year_ID,
        action.payload.isChecked
      ),
      makes: flipBool(
        state.makes,
        "make_ID",
        action.payload.make_ID,
        action.payload.isChecked
      ),
      models: flipBool(
        state.models,
        "model_ID",
        action.payload.model_ID,
        action.payload.isChecked
      ),
      locations: flipBool(
        state.locations,
        "location_ID",
        action.payload.location_ID,
        action.payload.isChecked
      ),
      events: flipBool(
        state.events,
        "event_ID",
        action.payload.event_ID,
        action.payload.isChecked
      ),
      categories: flipBool(
        state.categories,
        "id" || "famliy_ID",
        action.payload.bool === undefined
          ? action.payload.id
          : action.payload.family_ID,
        action.payload.isChecked,
        action.payload.bool,
        false,
        action.payload.string,
        action.payload.category_ID
      ),
    };
  },
  [actionTypes.REMOVE_BOOLEAN]: (state, action) => {
    return {
      ...state,
      years: flipBool(
        state.years,
        "year_ID",
        action.payload.year_ID,
        action.payload.isChecked
      ),
      makes: flipBool(
        state.makes,
        "make_ID",
        action.payload.make_ID,
        action.payload.isChecked
      ),
      models: flipBool(
        state.models,
        "model_ID",
        action.payload.model_ID,
        action.payload.isChecked
      ),
      locations: flipBool(
        state.locations,
        "location_ID",
        action.payload.location_ID,
        action.payload.isChecked
      ),
      events: flipBool(
        state.events,
        "event_ID",
        action.payload.event_ID,
        action.payload.isChecked
      ),
      categories: flipBool(
        state.categories,
        "id" || "famliy_ID",
        action.payload.bool === undefined
          ? action.payload.id
          : action.payload.family_ID,
        action.payload.isChecked,
        action.payload.bool,
        true,
        action.payload.string,
        action.payload.category_ID
      ),
    };
  },
  [actionTypes.GET_SEARCH_CRITERIA]: (state, action) => {
    const addID = (arr, newArr = []) => {
      arr.forEach((item, idx) => {
        newArr.push({
          ...item,
          location_ID: idx,
        });
      });
      return newArr;
    };

    const sortDesc = (arr) => {
      arr.sort((a, b) => b.value - a.value);
      return arr;
    };
    return {
      ...state,
      years: sortDesc(action.payload.years),
      makes: action.payload.makes,
      models: action.payload.models,
      categories: action.payload.categories,
      locations: addID(action.payload.locations),
      events: action.payload.events.map((x) => ({
        ...x,
        value: x.event_ID,
        date: x.value,
      })),
    };
  },
  [actionTypes.GET_MAKES_FOR_MODELS]: (state, action) => {
    const flipModelBool = () => {
      const models = state.modelsFilter;
      const payload = action.payload;
      if (models.length > 0) {
        models.forEach((model) => {
          payload.forEach((elm) => {
            if (model === elm.model_ID) {
              return (elm.isChecked = true);
            } else {
              return action.payload;
            }
          });
        });
      }
      return action.payload;
    };
    return {
      ...state,
      models: flipModelBool(),
    };
  },
  [actionTypes.CLEAR_MODELS_FILTER_CHECK]: (state, action) => {
    const removeModleFromFilter = () => {
      const models = state.models;
      const newArray = [];
      models.forEach((model) => {
        if (model.make_ID !== action.payload.make_ID) {
          if (model.isChecked) {
            newArray.push(model.model_ID);
          }
        }
      });
      return newArray;
    };

    return {
      ...state,
      modelsFilter: removeModleFromFilter(),
    };
  },
  [actionTypes.RESET_LOADER]: (state) => ({ ...state, loaded: false }),
  [actionTypes.CLEAR_SEARCH]: (state, action) => {
    return {
      ...state,
      filteredItems: [],
      items: [],
      yearsFilter: [],
      makesFilter: [],
      modelsFilter: [],
      categoriesFilter: [],
      locationsFilter: [],
      familiesFilter: [],
      sortValue: "",
      filteredDates: [],
      filteredIDs: [],
      statusFilter: 0,
      itemNavigator: [],
      models: [],
      years: state.years ? state.years.map((x) => ({ ...x, isChecked: false })) : [],
      makes: state.makes ? state.makes.map((x) => ({ ...x, isChecked: false })) : [],
      categories: state.categories ? state.categories.map((x) => ({ ...x, isChecked: false })) : [],
      locations: state.locations ? state.locations.map((x) => ({ ...x, isChecked: false })) : [],
      events: state.events ? state.events.map((x) => ({ ...x, isChecked: false })) : [],
      pageNumber: 1,
      totalRecordCount: 0,
    };
  },
  [actionTypes.SET_ITEM_NAVIGATOR]: (state, action) => {
    return {
      ...state,
      itemNavigator: action.payload,
    }
  }
});
