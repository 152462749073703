import ERRORS from '../../constants/errors';
import { createUnauthorizedCall } from '../../store/utils';
import { createAuthRemoteCall } from '../../store/utils';
import * as actionTypes from './action-types';
import { push } from 'react-router-redux';
import {
    getUpcomingAuctionsPreview,
    getRecentItems,
    getAuctionBlock,
} from '../main';
import { getWatchList } from '../watchlist';
import { getContactInfo, getUserInformation } from '../account';
import {getUpcomingEvents} from '../events';
import {updateInstabid} from '../live-auction';
import { checkForReload } from '../header';

export const login = (data, callback) => async (dispatch, getState) => {
    const { router: { location: { pathname } }, register: {isCreateAccount} } = getState()
    const result = await dispatch(createUnauthorizedCall(
        { fetch: actionTypes.LOGIN }, api => api.authorization.login)(data),
    );

    if (result.error) {
        switch (result.error) {
            case ERRORS.UNAUTHORIZED:
                dispatch({ type: actionTypes.SET_ERROR, error: ' Invalid email address or password' });
                break;
            default:
                dispatch({ type: actionTypes.SET_ERROR, error: 'Something went wrong' });
        }
    }
    if (callback && !result.error) {
        callback();
    }

    if (!result.error) {

        if (pathname !== '/' && !pathname.includes('welcome') && !pathname.includes('reset') && !pathname.includes('welcome-to-auctions') && !isCreateAccount) {
            let promise = new Promise((resolve) => {
                resolve(dispatch(push('/')));
            });
            promise.then(() => {
                dispatch(push(`${pathname}`));
            })
        }

        if (pathname === '/') {
            dispatch(reloadMain())
        }
    }
};

export const refresh = (params) => createUnauthorizedCall(
    { fetch: actionTypes.REFRESH }, api => api.authorization.refresh,
)(params);

export const proxy = params => async (dispatch) => {
    const result = await dispatch(createUnauthorizedCall(
        { fetch: actionTypes.PROXY }, api => api.authorization.proxy)(params)
    );
    return result;
}

export const handoff = (params) => async (dispatch) => {
        const result = await dispatch(
            createUnauthorizedCall(
                { fetch: actionTypes.HANDOFF },
                (api) => api.authorization.handoff
            )(params)
        );
        await Promise.all([
            dispatch(getUserInformation()),
            dispatch(getContactInfo()),
        ]);
        return result;  
};

export const logout = callback => async (dispatch, getState) => {
    dispatch({type: actionTypes.APP_CLEAR_STORE});
    dispatch({ type: actionTypes.LOGOUT });

    const {router: {location: {pathname}}} = getState();

    if (callback) {
        let promise = new Promise((resolve) => {
            if (callback) {
                resolve(
                    callback(),
                    dispatch(push('/'))
                )
            } else {
                dispatch(push('/'));
            }
            dispatch(updateInstabid({instaBid: false}));
        })

        promise.then(() => {
            if (pathname === "/") {
                dispatch(checkForReload());
                dispatch(reloadMain())
            }
        })
    }
};

export const updateAuthorizationState = item => ({
    type: actionTypes.UPDATE_AUTHORIZATION_STATE,
    payload: { object: item }
});

export const reloadMain = () => async dispatch => {
    Promise.all([
            await dispatch(getUpcomingAuctionsPreview()),
            await dispatch(getWatchList()),
            await dispatch(getRecentItems()),
            await dispatch(getUpcomingEvents({pageNumber: 1})),
            await dispatch(getUserInformation()),
            await dispatch(getAuctionBlock()),
    ])
}

export const getPageContent = data => createAuthRemoteCall(
  { fetch: actionTypes.GET_PAGE_CONTENT }, api => api.content.getPageContent, logout)(data);


export const resetAuthError = () => ({
    type: actionTypes.RESET_AUTH_ERROR
});