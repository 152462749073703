export const CREATE_EXTERNAL_USER = 'innovA/register#createExternalUser';
export const RESET_PASSWORD = 'innovA/register#resetPassword';
export const UPDATE_REGISTER_STATE = 'innovA/register#udpateRegisterState';
export const REQUEST_RESET_PASSWORD = 'innovA/register#requestResetPassword';
export const REQUEST_RESET_PASSWORD_WELCOME = 'innovA/register#requestResetPasswordWelcome';
export const VALIDATE_PHONE_REQUEST = 'innovA/register#externalValidatePhoneNumber';
export const VALIDATE_PHONE = 'innovA/register#externalValidatePhone';
export const REGISTER_USER_ACCOUNT = 'innovA/register#registerUserAccount';
export const GET_COUNTRIES = 'innovA/register#getCountries';
export const REGISTER_BTN_LOADER = 'innovA/register#registerBtnLoader';
export const CREATE_ACCOUNT = 'innovA/register#createAccount';
export const REGISTRATION_VALIDATE_PHONE_REQUEST = 'innovA/registration#registrationValidatePhoneRequest';
export const REGISTRATION_VALIDATE_PHONE = 'innovA/registration#registrationValidatePhone';
export const LOADING_VALIDATE_PHONE = 'innovA/registration#loadingValidatePhone';
export const BTN_SPINNER = 'innovA/register#btnSpinner';
export const VALIDATE_EMAIL_LOADING = 'innovA/register#validateEmailLoading';
export const VALIDATE_EMAIL = 'innovA/register#validateEmail';
export const VALIDATE_INVITED_USER = 'innovA/register/#validateInvitedUser';

