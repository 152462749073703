export const EventStatus = {
    Active: 1,
    Upcoming: 2,
    InProgress: 3,
    Completed: 4,
    Closed: 5,
    Catalogued: 6,
    LotNumbersAssigned: 7,
    DidNotRun: 8
};

export const EventStatusStrings = {
    1: 'Active',
    2: 'Upcoming',
    3: 'In Progress',
    4: 'Completed',
    5: 'Closed',
    6: 'Catalogued',
    7: 'Lot Numbers Assigned',
    8: 'Did Not Run'
};

