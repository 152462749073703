import { generateQueryString } from "../api/common";
import { parseQueryString } from "./get-query-params-search";
import { push } from "connected-react-router";

export default () => (dispatch, getState) => {
	const {
		router: { location },
	} = getState();
	let querySearch = {};

	const hasParams =
		location.pathname.includes("/account") ||
		location.pathname.includes("/info-center") ||
		location.pathname.includes("/content");

	const searchParams = hasParams ? location.search.replace(/\?.*/, "") : parseQueryString(location.search || "");

	delete searchParams.categories;
	delete searchParams.families;
	delete searchParams.makes;
	delete searchParams.models;
	delete searchParams.locations;
	delete searchParams.years;
	delete searchParams.events;
	delete searchParams.search_string;
	delete searchParams.page_number;
	delete searchParams.status;

	const newObject = {
		page_number: "1",
	};

	querySearch = Object.assign(searchParams, newObject);

	const query = generateQueryString(querySearch);
	const path = `${location.pathname}${query}`;

	dispatch(push(path));
};
