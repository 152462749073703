import {get, create} from './common';

export default ({
  getUpcomingEvents: async (params, token) => get(`/v1/auction/getupcomingevents?ShowAll=${params.showAll}`, token),
  getUpcomingEventsSorted: async (params, token) => get(`/v1/auction/getUpcomingEventsSorted?sortFilter=${params.sortFilter}&pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&showAll=${params.showAll}`, token),
  getPastEvents: async (params, token) => get(`/v1/auction/getPastEvents?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, token),
  getAuctionResults: async (params, token) => get(`/v1/auction/getAuctionResults?Event_ID=${params.event_ID}`, token),
  registerForAuctions: async (params, token) => create('/v1/auction/registerForAuctions', params, token),
  validateTermsAcceptanceByEventId: async (params, token) => get(`/v1/auction/validateTermsAcceptanceByEventId?Event_ID=${params.event_ID}`, token),
});
