import { push } from 'connected-react-router';
import ERRORS from '../constants/errors';
import { createSelector } from 'reselect';
import { errorHandler } from '../reducers/error';
import moment from 'moment';
import { REFRESH, LOGIN, PROXY } from '../reducers/authorization/action-types';
import { RESET_PASSWORD, REQUEST_RESET_PASSWORD, REQUEST_RESET_PASSWORD_WELCOME } from '../reducers/register/action-types';
import { refresh, proxy } from '../reducers/authorization';

export function createAuthRemoteCall(actionTypes, method, logout, download = false) {
    return genericRemoteAction(actionTypes, method, logout, true, download);
}

export function createUnauthorizedCall(actionTypes, method, logout) {
    return genericRemoteAction(actionTypes, method, logout, false);
}

function genericRemoteAction(actionTypes, method, logout, auth = true, download) {

    const action = params => async (dispatch, getState, { api }) => {
        const { fetch: fetchActionType, loading } = actionTypes;
        let authorization = getState().authorization || {};

        const storage = JSON.parse(localStorage.getItem('auctionsAuthorization'));

        const tokenExpired = moment(authorization.expiryDate).diff(new Date()) < 0;
        const refreshExpired = moment(authorization.refreshExpiryDate).diff(new Date()) < 0;

        const isNotMethod = fetchActionType !== LOGIN && fetchActionType !== RESET_PASSWORD && fetchActionType !== REQUEST_RESET_PASSWORD && fetchActionType !== REQUEST_RESET_PASSWORD_WELCOME;

        if (!authorization.status && fetchActionType !== PROXY && fetchActionType !== REFRESH && isNotMethod) {
            await dispatch(proxy());

            authorization = getState().authorization;
        } else if (refreshExpired && fetchActionType !== PROXY && fetchActionType !== REFRESH && isNotMethod) {
            await dispatch(proxy({
                user_ID: storage.user_ID
            }));
            authorization = getState().authorization;
        } else if (tokenExpired && fetchActionType !== REFRESH && fetchActionType !== PROXY && isNotMethod) {
            await dispatch(refresh({
                refresh_token: authorization.refresh_token
            }));
            authorization = getState().authorization;
        }

        if (loading) {
            dispatch({ type: loading });
        }

        const response = await method(api)(params, auth ? authorization.token : null);

        if (response.ok) {
            const payload = download ? await response.blob() : await response.json();
            const hasPayload = typeof (payload);
            dispatch({ type: fetchActionType, payload: hasPayload === 'string' ? [{ ...params }] : payload });
            return { payload };
        }
        if (response.error) {
            let promise = new Promise((resolve) => {
                resolve(dispatch(errorHandler({ errorCode: 500 })))
            })
            promise.then(() => {
                dispatch(push('/error'))
            })
        }
        switch (response.status) {
            case 400: {
                const data = await response.json();
                return { error: ERRORS.BAD_REQUEST, messages: data };
            }
            case 401:
                if (logout) {
                    dispatch(logout());
                    dispatch(push('/'));
                }
                return { error: ERRORS.UNAUTHORIZED };
            case 402:
                const data = await response.json();
                return { error: ERRORS.PAYMENT_REQUIRED, messages: data[0] };
            case 403:
                let promise = new Promise((resolve) => {
                    resolve(dispatch(errorHandler({ errorCode: 403 })))
                })
                promise.then(() => {
                    dispatch(push('/error'))
                })
                break;
            case 404:
                const result = await response.json();
                if (actionTypes.fetch.includes('getUserInfo')) {
                    localStorage.clear();
                } else {
                    let fouroFourpromise = new Promise((resolve) => {
                        resolve(dispatch(errorHandler({ errorCode: 404, errorMessage: result[0] })))
                    })
                    fouroFourpromise.then(() => {
                        dispatch(push('/error'));
                    });
                }
                break;
            case 422: {
                const data = await response.json();
                return { error: ERRORS.VALIDATION, fields: data.errors };
            }
            case 503:
                let fiveoThreepromise = new Promise((resolve) => {
                    resolve(dispatch(errorHandler({ errorCode: 503 })))
                })
                fiveoThreepromise.then(() => {
                    dispatch(push('/error'))
                })
                break;
            case 500:
                let fivehundredPromise = new Promise((resolve) => {
                        resolve(dispatch(errorHandler({ errorCode: 500})));
                })
                fivehundredPromise.then(() => {
                    dispatch(push('/error'))
                })
                break;
            default:
                return { error: ERRORS.UNKNOWN };
        }
    }

    return action;
}

export function normalizeArray(array = []) {
    return array.reduce((result, item) => {
        result[item.id] = item;

        return result;
    }, {});
}

export const denormalizeEntitiesArray = createSelector(
    entities => entities || {},
    entities => {
        return Object.keys(entities).reduce((result, key) => {
            result.push(entities[key]);

            return result;
        }, []);
    },
);

export function createReducer(initialState = null, actions = {}) {
    return (state = initialState, action) => {
        if (Object.prototype.hasOwnProperty.call(actions, action.type)) {
            return actions[action.type](state, action);
        }
        return state;
    };
}
