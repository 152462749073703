import { create, get } from './common';

export default {
    createExternalUser: async (params, token) => create(`/v1/Auction/CreateExternalUser`, params, token),
    resetPassword: async (params, token) => create(`/v1/user/resetPassword`, params, token),
    requestPasswordReset: async (params, token) => create(`/v1/user/RequestPasswordReset`, params, token),
    requestPasswordResetWelcome: async (params, token) => create(`/v1/user/RequestPasswordResetWelcome`, params, token),
    externalValidatePhoneRequest: async (params, token) => create(`/v1/auction/externalValidatePhoneRequest`, params, token),
    externalValidatePhone: async (params, token) => create(`/v1/auction/externalValidatePhone`, params, token),
    registerUserAccount: async (params, token) => create(`/v1/Auction/RegisterUserAccount`, params, token),
    getCountries: async (params, token) => get(`/v1/System/GetCountries`, token),
    createAccount: async (params, token) => create(`/v1/Registration/CreateAccount`, params, token),
    validatePhoneRequest: async (params, token) => create(`/v1/Registration/ValidatePhoneRequest`, params, token),
    validatePhone: async (params, token) => create(`/v1/Registration/ValidatePhone`, params, token),
    validateEmail: async (params, token) => create(`/v1/User/ValidateEmail`, params, token),
		validateInvitedUser: async (params, token) => create(`/v1/User/ValidateInvitedUser`, params, token)
};