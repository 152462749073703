export default class Logger {

  static log (/**/) {
    if (process.env.NODE_ENV === 'production') {
      return null
    }
    for (let i = 0; i < arguments.length; i++) {
        console.log(arguments[i])
    }
  }

  static debug (/**/) {
    if (process.env.NODE_ENV === 'production') {
        return null
    }
    for (let i = 0; i < arguments.length; i++) {
        console.debug(arguments[i])
    }
  }

  static error (/**/) {
    if (process.env.NODE_ENV === 'production') {
        return null
    }
    for (let i = 0; i < arguments.length; i++) {
        console.error(arguments[i])
    }
  }
}