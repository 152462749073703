import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";
import { APP_CLEAR_STORE } from "../authorization/action-types";
import { onlyNums } from "../../utils/common";
import Logger from "../../utils/logger";

const initialState = {
  billingSameAsPhysical: true,
  isCurrentTermsAcceptance: true,
  offers: [],
  listedItems: [],
  soldItems: [],
  loading_items: false,
  paymentProfile: [],
  hasError: false,
  errorText: "",
  wonPurchasedItems: [],
  lostItems: [],
  registeredAuctions: [],
  loading_update_account: true,
  invoices: [],
  loading_invoices: false,
  invoiceHtml: { loaded_invoice: false },
  releaseTicketHtml: { loaded_ticket: false },
  loading_add_user_payment: true,
  paymentMethodInitialValues: {
    label: "",
    value: "",
    id: null,
    paymentMethod_ID: null,
  },
  step: 1,
  loading_customer_add_payment: true,
  loading_payment_methods: true,
  loading_deposit_methods: true,
  accountInfo: {
    loading_update_address: true,
    address: {
      formatted: [],
    },
    billingAddress: {
      formatted: [],
    },
    verifiedAddress: {
      formatted: [],
    },
  },
  surcharge: {
    fee: 0,
    percentage: 0,
  },
  taxExemptionTypes: [],
  contactInfo: {
    accounts: [],
  },
  loadingContactInfo: true,
};

export default createReducer(initialState, {
  [actionTypes.UPDATE_CONTACT_BIDDERTAG]: (state, action) => {
    let buyingEnabled = action.payload.accounts.some(account => account.buyingEnabled);
    return {
      ...state,
      ...action.payload,
      hasAccount: action.payload.accounts.length > 0 ? true : false,
      country_ID: action.payload.country_ID ? action.payload.country_ID : action.payload.defaultCountry_ID,
      phoneCountry_ID: action.payload.defaultCountry_ID,
      phoneNumber: action.payload.phone1,
      contactInfo: action.payload,
      loadingContactInfo: false,
      buyingEnabled: buyingEnabled
    };
  },
  [APP_CLEAR_STORE]: () => ({ ...initialState }),
  [actionTypes.GET_TAX_EXEMPTION_TYPES]: (state, action) => ({ ...state, taxExemptionTypes: action.payload }),
  [actionTypes.GET_HANDOFF_TOKEN]: (state) => ({ ...state }),
  [actionTypes.LOADING_USER]: (state) => ({ ...state, loaded_user: false }),
  [actionTypes.LOADING_USER_OFFERS]: (state) => ({ ...state, loaded_offers: false }),
  [actionTypes.LOADING_ITEMS]: (state) => ({ ...state, loading_items: false }),
  [actionTypes.LOADING_UPDATE_ACCOUNT]: (state) => ({ ...state, loading_update_account: false }),
  [actionTypes.LOADING_INVOICES]: (state) => ({ ...state, loading_invoices: false }),
  [actionTypes.GET_USER_INFO]: (state, action) => {
    const { buyingEnabled, ...userProfile } = action.payload.userProfile; //exclude buyingEnabled From userProfile this is bad 
    return {
      ...state,
      ...userProfile,
      accountInfo: {
        ...state.accountInfo,
        ...action.payload.userProfile.accountInfo
      },
      loaded_user: true
    }
  },
  [actionTypes.GET_USER_OFFER_HISTORY]: (state, action) => ({ ...state, offers: action.payload.items, loaded_offers: true, useStockNumber: action.payload.useStockNumber }),
  [actionTypes.UPDATE_ACCOUNT_STATE]: (state, action) => ({ ...state, ...action.payload.object }),
  [actionTypes.UPDATE_USER_ACCOUNT]: (state, action) => ({ ...state, ...action.payload, loading_update_account: true }),
  [actionTypes.SET_USER_PERMISSIONS]: (state, action) => ({ ...state, ...action.payload }),
  [actionTypes.GET_USER_PERMISSIONS]: (state, action) => ({ ...state, ...action.payload }),
  [actionTypes.GET_LISTED_ITEMS]: (state, action) => ({ ...state, listedItems: action.payload, loading_items: true }),
  [actionTypes.GET_SOLD_ITEMS]: (state, action) => ({ ...state, soldItems: action.payload, loading_items: true }),
  //[actionTypes.GET_WONPURCHASED_ITEMS]: (state, action) => ({ ...state, wonPurchasedItems: action.payload, loading_items: true }),
  [actionTypes.GET_LOST_ITEMS]: (state, action) => ({ ...state, lostItems: action.payload, loading_items: true }),
  [actionTypes.GET_REGISTERED_AUCTIONS]: (state, action) => ({ ...state, registeredEvents: action.payload, loading_items: true }),
  //TODO: PAYMENT METHODS
  // [actionTypes.ADD_USER_PAYMENT_METHOD]: (state, action) => ({ ...state, ...action.payload, loading_add_user_payment: true }),
  [actionTypes.LOADING_ADD_USER_PAYMENT]: (state) => ({ ...state, loading_add_user_payment: false }),
  [actionTypes.EDIT_USER_PAYMENT_METHOD]: (state, action) => ({ ...state, ...action.payload, loading_add_user_payment: true }),
  [actionTypes.GET_USER_PAYMENT_METHODS]: (state, action) => ({ ...state, paymentProfile: action.payload, loaded_user: true }),
  [actionTypes.DELETE_USER_PAYMENT_METHOD]: (state, action) => ({ ...state, ...action.payload, loading_add_user_payment: true }),
  [actionTypes.CREATE_USER_PAYMENT_PROFILE]: (state, action) => ({ ...state, paymentProfile_ID: action.payload.profile_ID }),
  [actionTypes.CHARGE_USER_PAYMENT_PROFILE]: (state, action) => ({ ...state, ...action.payload }),
  [actionTypes.GET_WONPURCHASED_ITEMS]: (state, action) => {
    const tempArr = [];
    action.payload.forEach((item) => {
      tempArr.push({
        ...item,
        spinner: false,
      });
    });
    return {
      ...state,
      wonPurchasedItems: tempArr,
      loading_items: true,
    };
  },
  [actionTypes.GET_INVOICES]: (state, action) => {
    const tempArr = [];
    action.payload.invoices
      .filter((invoice) => invoice.status_ID !== 4)
      // .filter(
      //   (invoice) =>
      //     (invoice.status_ID !== 4 || invoice.status_ID !== 2) &&
      //     invoice.balance !== 0
      // )
      .forEach((item) => {
        tempArr.push({
          ...item,
          spinner: false,
        });
      });
    return {
      ...state,
      invoices: tempArr,
      loading_invoices: true,
    };
  },
  [actionTypes.GET_INVOICE_HTML]: (state, action) => ({
    ...state,
    invoiceHtml: { ...action.payload, loaded_invoice: true },
  }),
  [actionTypes.GET_RELEASE_TICKET_HTML]: (state, action) => ({
    ...state,
    releaseTicketHtml: { ...action.payload, loaded_ticket: true },
  }),
  [actionTypes.LOADING_GET_INVOICE_HTML]: (state) => ({ ...state, invoiceHtml: { loaded_invoice: false } }),
  [actionTypes.LOADING_GET_RELEASE_TICKET_HTML]: (state) => ({ ...state, releaseTicketHtml: { loaded_ticket: false } }),
  [actionTypes.GET_INVOICE_PDF]: (state, action) => ({ ...state, ...action.payload, loading_download_pdf: false }),
  [actionTypes.GET_RELEASE_TICKET_PDF]: (state, action) => ({ ...state, ...action.payload, loading_download_pdf: false }),
  [actionTypes.LOADING_RELEASE_DOWNLOAD_PDF]: (state, action) => {
    const temp = state.wonPurchasedItems;
    temp.forEach((item) => {
      if (item.item_ID === action.payload.id) {
        item.spinner = action.payload.spinner;
      }
    });
    return {
      ...state,
      wonPurchasedItems: temp,
    };
  },
  [actionTypes.LOADING_DOWNLOAD_PDF]: (state, action) => {
    const temp = state.invoices;
    temp.forEach((item) => {
      if (item.invoice_ID === action.payload.id) {
        item.spinner = action.payload.spinner;
      }
    });
    return {
      ...state,
      invoices: temp,
    };
  },
  [actionTypes.LOADING_PAY_INVOICE]: (state) => ({ ...state, loading_payment: true }),
  [actionTypes.PAY_INVOICE]: (state, action) => {
    const tempArr = [];
    action.payload.invoices.forEach((item) => {
      tempArr.push({
        ...item,
        spinner: false,
      });
    });
    return {
      ...state,
      ...action.payload,
      loading_payment: false,
    };
  },
  [actionTypes.GET_PAYMENT_METHODS]: (state, action) => ({ ...state, ...action.payload, loading_payment_methods: true }),
  [actionTypes.LOADING_CUSTOMER_ADD_PAYMENT]: (state) => ({ ...state, loading_add_user_payment: false }),
  [actionTypes.ADD_CUSTOMER_PAYMENT_METHOD]: (state, action) => ({ ...state, ...action.payload.creditCardResult, loading_add_user_payment: true }),
  [actionTypes.LOADING_PAYMENT_METHODS]: (state) => ({ ...state, loading_payment_methods: false }),
  [actionTypes.PAY_INVOICE_CC]: (state, action) => ({ ...state, ...action.payload, loading_payment: false }),
  [actionTypes.LOADING_DEPOSIT_METHOD]: (state) => ({ ...state, loading_deposit_methods: false }),
  [actionTypes.CREDIT_CARD_DEPOSIT]: (state, action) => ({ ...state, ...action.payload, loading_deposit_methods: true }),
  [actionTypes.SAVED_METHOD_DEPOSIT]: (state, action) => ({ ...state, ...action.payload, loading_deposit_methods: true }),
  [actionTypes.GET_SURCHARGE]: (state, action) => ({ ...state, surcharge: { ...action.payload.surcharge } }),
  [actionTypes.CLEAR_SURCHARGE]: (state, action) => ({ ...state, surcharge: { ...action.payload.object } }),
  [actionTypes.LOADING_UPDATE_ADDRESS]: (state) => ({ ...state, accountInfo: { ...state.accountInfo, loading_update_address: false } }),
  [actionTypes.UPDATE_PHYSICAL_ADDRESS]: (state, action) => ({
    ...state,
    accountInfo: { ...state.accountInfo, address: { ...state.accountInfo.address, ...action.payload.address }, loading_update_address: true },
  }),
  [actionTypes.UPDATE_BILLING_ADDRESS]: (state, action) => ({ ...state, accountInfo: { ...state.accountInfo, billingAddress: { ...action.payload.address }, loading_update_address: true } }),
  [actionTypes.ADD_TAX_EXEMPTION]: (state, action) => {
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        taxExemptions: action.payload,
      },
    };
  },
  [actionTypes.GET_CONTACT_INFO]: (state, action) => {
    let buyingEnabled = action.payload.accounts.some(account => account.buyingEnabled);
    return {
      ...state,
      ...action.payload,
      hasAccount: action.payload.accounts.length > 0 ? true : false,
      country_ID: action.payload.country_ID ? action.payload.country_ID : action.payload.defaultCountry_ID,
      phoneCountry_ID: action.payload.defaultCountry_ID,
      phoneNumber: action.payload.phone1,
      contactInfo: action.payload,
      loadingContactInfo: false,
      buyingEnabled: buyingEnabled
    };
  },
  [actionTypes.UPDATE_PHYSICAL_ADDRESS_V2]: (state, action) => {
    return {
      ...state,
    }
  },
  [actionTypes.UPDATE_BILLING_ADDRESS_V2]: (state, action) => {
    return {
      ...state,
    }
  },
  [actionTypes.UPDATE_ACCOUNT_ADDRESS]: (state, action) => {
    Logger.log('UPDATE_ACCOUNT_ADDRESS =>', action.payload)
    const { account_ID, address, type } = action.payload;

    let accountToUpdate = state.contactInfo.accounts.find(x => x.account_ID === account_ID);
    accountToUpdate[type] = address;

    return {
      ...state,
      contactInfo: {
        ...state.contactInfo,
        accounts: state.contactInfo.accounts.map(x => x.account_ID === account_ID ? accountToUpdate : x)
      }
    }
  },
  [actionTypes.UPDATE_TERMS_ACCEPTANCE]: (state, action) => {
    return {
      ...state,
      isCurrentTermsAcceptance: action.payload
    }
  },
});
